import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IAuthUser } from "../models/user";

const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 1000; // Delay between retries in milliseconds
const TOKEN_RETRY_DELAY_MS = 2000; // 2-second delay if the token is empty

/**
 * GET API call using Axios with retry mechanism and delay between retries.
 * If the token is empty, it will retry fetching the token with a delay.
 * @returns - Logged in User details type of IAuthUser
 */
export const validateOktaToken = async (): Promise<IAuthUser> => {
  let attempts = 0;
  let tokenAttempts = 0;
  let token: string | null = null;

  // Retry logic for fetching the token if it's empty
  while (tokenAttempts < MAX_RETRIES && !token) {
    const user = JSON.parse(localStorage.getItem("okta-token-storage")!);
    token = _.get(user, "idToken.idToken");

    if (!token) {
      tokenAttempts++;
      console.warn(`Token not found. Waiting ${TOKEN_RETRY_DELAY_MS / 1000} seconds before retrying...`);
      await new Promise((resolve) => setTimeout(resolve, TOKEN_RETRY_DELAY_MS));
    }
  }

  if (!token) {
    throw new Error("Failed to retrieve the token after multiple attempts.");
  }

  // Retry logic for API call
  while (attempts < MAX_RETRIES) {
    try {
      const response = await axios.post(
        getUrl(ApiType.VALIDATE_OKTA_TOKEN),
        { token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      attempts++;
      let message = ConstantMessage.validateOktaToken;
      const status = _.get(error, "response.status");
      if(!status)  throw new Error(ConstantMessage.statusCodeNotFound);
      const errorMessage = _.get(error, "response.data.error.message") || _.get(error, "response.data.errors[0].message");

      if(status === 503) {
        message = _.get(error, "response.data.errors[0].message", message);
        throw ConstantMessage.deploymentInprogressError;
      }
      if (status === 500) {
        message = ConstantMessage.serverError;
      } else {
        message = errorMessage;
      }

      if (attempts < MAX_RETRIES) {
        console.warn(`Attempt ${attempts} failed. Retrying in ${RETRY_DELAY_MS / 1000} seconds...`);
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY_MS));
      } else {
        console.error("Maximum retry attempts reached.");
        throw new Error(message);
      }
    }
  }

  throw new Error(ConstantMessage.validateOktaToken); // Shouldn't be reached due to earlier throws
};
