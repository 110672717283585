import axios from "axios";
import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import _ from "lodash";

/**
 * POST API call using Axios
 */

export const Log = async (params: {errorMessage: string, error?: any} = {errorMessage: ""}) => {
  try {
    const response = await axios.post(getUrl(ApiType.LOGGLY_LOG), params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.logglyLogAPIError;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }
    throw message;
  }
};
