import { useNavigate } from "react-router-dom";
import AppRoutes from "./components/shared/AppRoutes";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import * as _ from "lodash";
import axios from "axios";
import { useEffect } from "react";
import { resetState } from "./AppState/resetStateActions";
import { useDispatch } from "react-redux";
import Notification from "./components/shared/Notification";
import { isPrivilegedUser, logUserIn } from "./AppState/logUserIn";
import { Log } from "./API/log";
import { validateOktaToken } from "./API/validateOktaToken";
import { PopoutNotification } from "./AppState/popoutNotification";
import NOTIFICATION_TYPE from "./AppState/NotificationType";
import { ConstantMessage } from "./constants/constantsMsg";

const ERROR_MESSAGES = {
  USER_NOT_ACTIVE: "User is not active. Please log out of Okta and log in again to retry.",
  TOKEN_REFRESH_FAILED: "Token refresh failed after multiple retries. Please log out of Okta and log in again to retry.",
  DB_USER_INFO_MISSING: "Login failed because user information is missing in the database.",
};

const RootApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Axios request interceptor
  axios.interceptors.request.use(
    async (request) => {
      const authUser = JSON.parse(localStorage.getItem("authUser")!);
      const jwtToken = _.get(authUser, "jwtToken", "");

      if (jwtToken) {
        _.set(request, "headers.jwt_token", jwtToken);
      } else if (!request.url!.includes("/auth/tokens/verification") && !request.url!.includes("/system/log") && !request.url!.includes("/deployment-status")) {
        await Log({ errorMessage: `Front-end JWT Token is missing, url: ${request.url}` });
      }

      return request;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const { status } = _.get(error, "response");
      if(!status) throw new Error(ConstantMessage.statusCodeNotFound)
      const errorMessage = _.get(error, "response.data.error.message", "");
      console.error("Api Error :", errorMessage);
      // Handle 503 Service Unavailable (Deployment in progress) scenario
      const deploymentStatus = error.response.headers.get('X-Deployment-Status');

      if (status === 503 && deploymentStatus) {
        console.log("Deployment in progress. Redirecting to maintenance page...");
        navigate(`/maintenance`);
        return Promise.reject(new Error("Deployment in progress. Redirecting to maintenance page"));
      }

      if (status === 401 && !originalRequest._retry) {
        if (errorMessage.includes("User is not active")) {
          console.log("OKTA user not active. Reloading application for fresh OKTA Token.");
          Log({ errorMessage: `Front-end API failed, url: ${originalRequest.url}`, error });
          dispatch(PopoutNotification(NOTIFICATION_TYPE.ERROR, ERROR_MESSAGES.USER_NOT_ACTIVE, Date.now().toString()));
          navigate(`/401`);
        }

        const isDBUserNotValid =
          errorMessage.includes("Login failed due to missing username") ||
          errorMessage.includes("Login failed due to missing terminal information") ||
          errorMessage.includes("User not exist") ||
          errorMessage.includes("Login failed because user full name information is missing in the database");

        if (!isDBUserNotValid) {
          originalRequest._retry = true;
          try {
            const user = await validateOktaToken();
            localStorage.setItem("authUser", JSON.stringify(user));
            dispatch(logUserIn(true));
            dispatch(isPrivilegedUser(_.get(user, "isPrivilegedUser", false)));
            originalRequest.headers["Authorization"] = `Bearer ${user.jwtToken}`;
            return axios(originalRequest);
          } catch (tokenRefreshError) {
            console.log("Token refresh failed:", tokenRefreshError);
            Log({ errorMessage: `Front-end Token refresh failed: ${JSON.stringify(tokenRefreshError)}` });
            dispatch(PopoutNotification(NOTIFICATION_TYPE.ERROR, ERROR_MESSAGES.TOKEN_REFRESH_FAILED, Date.now().toString()));
            navigate(`/401`);
          }
        } else {
          console.log("Login Database Level error :", errorMessage);
          Log({ errorMessage: `Front-end API failed: ${JSON.stringify(errorMessage)}` });
          dispatch(PopoutNotification(NOTIFICATION_TYPE.ERROR, errorMessage || ERROR_MESSAGES.DB_USER_INFO_MISSING, Date.now().toString()));
          navigate(`/401`);
        }
      }

      console.log("Login error:", errorMessage);
      return Promise.reject(error);
    }
  );

  const resetProperties = () => {
    dispatch(resetState("SOFT_RESET"));
  };

  useEffect(() => {
    resetProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Header />
      <AppRoutes />
      <Footer />
      <Notification />
    </div>
  );
};

export default RootApp;
