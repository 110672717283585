import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Grid,
  FormControl,
  TextField,
  Checkbox,
  Snackbar,
  Button,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "./dsInvestigation.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { State } from "../../../components/shared/Notification";
import {
  DSInvestigationInitValue,
  DSInvestigationSchema,
} from "../../../schema/dsInvestigation";
import { FieldArray, FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import {
  ASInvestigationWizardStep,
  EShortageInvestigationStatus,
  StepStatusMap,
  EASInvestigationFieldsErrorMessage,
} from "../../../constants/shortage";

import {
  DSDay1Fields,
  DSDay10Fields,
  DSDay15Fields,
  DSDay20Fields,
  DSDay2Fields,
  DSDay3Fields,
  DSDay4Fields,
  DSDay5Fields,
  DSYesNoFields,
} from "../../../constants/dsException";
import { useDispatch, useSelector } from "react-redux";
import { PopoutNotification } from "../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../../AppState/NotificationType";
import { isFormValueChanged } from "../../../AppState/isFormValueChanged";
import Loader from "../../../components/loader/loader";
import {
  CombineTowJsonObject,
  CompareObjects,
  HandleKeyDown,
  HandleNumberMaxLength,
  HtmlToImage,
  TrimStrings,
} from "../../../helpers/utils.helper";
import {
  EDSInvestigationFields,
  IDSInvestigation,
  IDSDay5Investigation,
} from "../../../models/dsException";
import _, { debounce } from "lodash";
import DSInvestigationTemplate from "../../../templates/dsInvestigationTemplate";
import { FileUploadAPI } from "../../../API/fileUpload";
import { IUploadFile } from "../../../models/master";
import { EUploadKey } from "../../../constants/app";
import AppState from "../../../AppState";
import { SaveOrSubmitDSInvestigationAPI } from "../../../API/saveOrSubmitDSInvestigation";
import SendAPBImageList from "../../../components/sendAPBImageList/sendAPBImageList";

type Props = {
  exceptionType: string;
  osdNumber: number;
  proNumber?: number | string | null;
  isEditMode?: boolean;
  setInvestigationStatus: (status: number) => void;
  status?: number;
  isFreightLocated?: boolean;
  investigationDetails?: any;
};
interface TooltipState {
  [key: string]: boolean;
}

const DsInvestigation: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState<number>(
    StepStatusMap[EShortageInvestigationStatus.exceptionEntry]
  );
  // dispatch(userRole("Supervisor, OS&d"));
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    props?.isEditMode || false
  );
  const [resetTimestamps, setResetTimestamps] = useState<string>(
    Date.now().toString()
  );
  const [formKey, setFormKey] = useState<string>(Date.now().toString());
  const [open, setOpen] = useState<boolean>(false);
  const [isFreightLocated, setIsFreightLocated] = useState<boolean>(false);
  const [status, setStatus] = React.useState<number>(1);
  const [lastStatusNumber, setLastStatusNumber] = React.useState<number>(1);
  const [invalidFields, setInvalidFields] = React.useState<string[]>([]);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(
    props?.isEditMode || false
  );
  const [dateKey, setDateKey] = React.useState<string>(Date.now().toString());
  const isPrivilegedUser: boolean = useSelector(
    (state: AppState) => state.isPrivilegedUser
  );
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const [initValues, setInitValues] = React.useState<IDSInvestigation>(
    _.mergeWith(
      {},
      DSInvestigationInitValue,
      props?.investigationDetails,
      CombineTowJsonObject
    )
  );

  // Handle image selection
  const handleImageSelect = (imageUrl: string) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(imageUrl)
        ? prevSelectedImages.filter((url) => url !== imageUrl)
        : [...prevSelectedImages, imageUrl]
    );
  };

  /**
   * Determines whether the "Send APB" option should be displayed based on the current state and form values.
   * .
   */
  const showSendAPB = () => {
    const { osdNumber } = props;
    const { highValue, details7818_2, check7818_2 } = values;
    const {
      highValue: initialHighValue,
      details7818_2: initialDetails7818_2,
      check7818_2: initialCheck7818_2,
    } = initValues;

    const isStepActive = activeStep === StepStatusMap[8];
    const isFormEnabled = !formFieldsDisabled;
    const hasChangedValue =
      !initialDetails7818_2 ||
      initialCheck7818_2 !== "Y" ||
      initialHighValue !== "Y";

    return (
      osdNumber &&
      isStepActive &&
      isFormEnabled &&
      highValue === "Y" &&
      details7818_2 &&
      check7818_2 === "Y" &&
      hasChangedValue
    );
  };

  /**
   * Handles the process of uploading investigation images and updating the UI based on the result.
   *  1. **Determine IDs to Process**: Creates a list of IDs to process based on the `ASInvestigationWizardStep`. If `isFreightLocated` is `true`, slices the IDs array to include only the relevant IDs up to `lastStatusNumber - 1`.
   *
   * 2. **Generate Images**: Maps over the IDs to generate image promises using the `HtmlToImage` function. Each promise captures the image of the element with an ID formatted as `investigation-${day}` and names the image file sequentially.
   *
   * 2. **Upload Images**: Waits for all image promises to resolve. Constructs a request input object for file upload with properties such as `osdNumber`, `docType`, and `uploadKey`.
   *
   * 4. **Send Upload Request**: Calls the `FileUploadAPI` function to upload the images along with the request input. Handles the response:
   *    - **Success**: If the upload is successful,
   *    - **Failure**: If the upload fails, dispatches a notification with an error message.
   *
   */
  const uploadInvestigation = async () => {
    setPageLoading(true);
    let ids = ASInvestigationWizardStep;
    if (isFreightLocated) {
      ids = ids.slice(0, lastStatusNumber - 1);
    }
    const imagePromises = ids.map(async (day: string, index: number) => {
      const img = await HtmlToImage(
        `investigation-${day}`,
        `${index + 1}.jpeg`
      );
      return img;
    });
    const images = await Promise.all(imagePromises);
    const reqInput: IUploadFile = {
      osdNumber: props.osdNumber,
      docType: "FX",
      uploadKey: EUploadKey.pro,
    };
    FileUploadAPI(images, reqInput)
      .then((res: any) => {
        setPageLoading(false);
        const errors = _.get(res, "errors", []);
        const notificationType =
          errors.length > 0
            ? NOTIFICATION_TYPE.ERROR
            : NOTIFICATION_TYPE.SUCCESS;
        const message =
          errors.length > 0
            ? errors.join(", ")
            : res.result || "OS&D Investigation Image uploaded successfully";

        dispatch(
          PopoutNotification(notificationType, message, Date.now().toString())
        );
      })
      .catch((error: any) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof error === "string"
              ? error
              : `OS&D Investigation Image uploading failed`,
            Date.now().toString()
          )
        );
      });
  };

  // If active day investigation is completed the checkboxes is disabled of day
  const isControlDisabled = (fieldName: keyof IDSInvestigation) => {
    const value = "Y";
    const isChecked =  typeof initValues[fieldName] === "string" &&
    initValues[fieldName] === value;
    if(status > EShortageInvestigationStatus.day20InProgress && isChecked) {
      return true;
    }
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    if (isChecked) {
      return true;
    }
  };

  /**  
    Switch the investigation day step
    Allows the user to navigate to a previous day's investigation from the current day in progress
    However, the user cannot proceed to the next day until the current day's investigation is completed
  */

  const handleStep = (step: number) => () => {
    const iStatus = isFreightLocated ? lastStatusNumber : status;
    if (StepStatusMap[iStatus as keyof typeof StepStatusMap] < step) return;
    setFormFieldsDisabled(true);
    setIsEditMode(true);
    reset();
    setActiveStep(step);
  };

  const theme = createTheme({
    components: {
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: {
            display: "none", // This will hide the icon container
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            display: "none", // This will hide the connector line
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          horizontal: {
            width: "12.5%", // This will set the width of each step to 12.5%
            padding: "0", // This will set the padding to 0
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            margin: "initial", // This will set the margin to its initial value
            padding: "0", // This will set the padding to its initial value
            zIndex: "1", // This will set the z-index to 1
            height: "50px",
            fontWeight: "bold",
            fontStyle: "italic",
            letterSpacing: "1px",
            textTransform: "uppercase",
          },
        },
      },
    },
  });

  // Declare a new state variable for tooltips with a default empty object
  const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

  // Function to handle tooltip close
  const handleTooltipClose = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: false }));
  };

  // Function to handle tooltip open
  const handleTooltipOpen = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: true }));
  };

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // Enable fields in edit mode upon clicking the edit button
  const enableEditing = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setFormFieldsDisabled(false); // Enables the form fields for editing
  };

  // Initialize form handling using Formik
  // This setup uses Formik to manage the form state, validation, and submission process.
  const form = useFormik({
    validationSchema: DSInvestigationSchema, // Validation schema for the form fields
    validateOnChange: false, // Disable validation on field change
    initialValues: initValues, // Initial values for the form fields
    onSubmit: () => {
      saveOrSubmit("submit"); // Function to handle form submission
    },
  });

  // Destructure various handlers and values provided by Formik for easy use within the component
  const {
    values, // Current values of the form fields
    handleChange, // Handler for changing form field values
    handleBlur, // Handler for managing field blur events
    validateField, // Function to validate a specific field
    setFieldValue, // Function to set the value of a specific field
    validateForm, // Function to validate the entire form
    resetForm, // Function to reset the form to its initial values
  } = form;

  /**
   * Validates a field based on the value of a dependent field and the current step in the process.
   *
   * - Adds the field to `invalidFields` if the dependent field matches the expected value (`val`),
   *   the field is empty, and it is not already marked as invalid.
   * - Removes the field from `invalidFields` if the conditions are not met.
   */
  const isValidDependedField = (
    fieldName: keyof IDSInvestigation,
    dependedField: keyof IDSInvestigation,
    activeStep: number,
    val: string = "Y"
  ) => {
    // Exit early if the status step is not reached
    // if (StepStatusMap[status as keyof typeof StepStatusMap] > activeStep) return;
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;

    const fieldValue = values[fieldName]?.toString();
    const dependedValue = values[dependedField];
    let otherVal = "";

    if (fieldName === "confirm") {
      otherVal = "N";
    }

    // Add field to invalidFields if conditions are met
    setInvalidFields((prevInvalidFields) => {
      // Determine if we should add or remove the fieldName
      const shouldAdd =
        dependedValue === val &&
        (!fieldValue ||
          fieldValue === "" ||
          fieldValue === undefined ||
          fieldValue === otherVal);

      if (shouldAdd) {
        // Add the fieldName to the list if it's not already present
        return prevInvalidFields.includes(fieldName)
          ? prevInvalidFields
          : [...prevInvalidFields, fieldName];
      } else {
        // Remove the fieldName from the list if it's present
        return prevInvalidFields.filter((field) => field !== fieldName);
      }
    });
    // Update state with modified invalid fields
    // setInvalidFields(Array.from(updatedInvalidFields));
  };

  /**
   * Adds or removes field names related to the investigation details from the `invalidFields` list based on the provided type.
   *
   * - **Type "add"**: Adds fields with names based on the current length of `investigationDetails` to `invalidFields`.
   * - **Type "remove"**: Removes fields with names based on the current length of `investigationDetails` from `invalidFields`.
   *
   */
  const addRemove = (type: string = "add") => {
    // Exit if the current step is not active
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;

    const fields = [
      `terminal${values?.investigationDetails?.length}`,
      `talkedTo${values?.investigationDetails?.length}`,
      `updates${values?.investigationDetails?.length}`,
    ];

    setInvalidFields((prevFields) =>
      type === "add"
        ? [...prevFields, ...fields]
        : prevFields.filter((field) => !fields.includes(field))
    );
  };

  const isValueExist = (
    fieldName: keyof IDSInvestigation | string,
    index?: number
  ) => {
    // Exit if the current step is not active
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;

    const fieldKey = index !== undefined ? `${fieldName}${index}` : fieldName;
    const value =
      index !== undefined
        ? _.get(values, `investigationDetails[${index}][${fieldName}]`, "")
        : values[fieldName as keyof IDSInvestigation] ?? "";

    const initValue =
      index !== undefined
        ? _.get(initValues, `investigationDetails[${index}][${fieldName}]`, "")
        : initValues[fieldName as keyof IDSInvestigation];
    const isInvalid =
      (value == null || value === "") && // Covers null, undefined, and empty string
      (initValue !== "" &&  initValue != null ) && // Checks if initValue is neither an empty string, null, nor undefined
      !invalidFields.includes(fieldKey);

    // Update invalid fields
    setInvalidFields((prevInvalidFields) =>
      isInvalid
        ? [...prevInvalidFields, fieldKey]
        : prevInvalidFields.filter((field) => field !== fieldKey)
    );
  };

  const reset = () => {
    resetForm();
    setResetTimestamps(Date.now().toString());
    setInvalidFields([]);
    const source: IDSInvestigation = JSON.parse(JSON.stringify(initValues));
    const fields: string[] = [
      ...DSDay1Fields,
      ...DSDay2Fields,
      ...DSDay3Fields,
      ...DSDay4Fields,
      ...DSDay10Fields,
      ...DSDay15Fields,
      ...DSDay20Fields,
      ...DSDay5Fields,
    ];
    fields.forEach((f: string) => {
      const fieldValue = source[f as keyof IDSInvestigation];
      setFieldValue(f, fieldValue);
    });
    setFieldValue(`pumManifest`, _.get(source, `pumManifest`, undefined));

    _.get(source, "investigationDetails", []).forEach(
      (obj: any, index: number) => {
        setFieldValue(
          `investigationDetails[${index}].terminal`,
          _.get(obj, `terminal`, undefined)
        );
        setFieldValue(
          `investigationDetails[${index}].talkedTo`,
          _.get(obj, `talkedTo`, undefined)
        );
        setFieldValue(
          `investigationDetails[${index}].updates`,
          _.get(obj, `updates`, undefined)
        );
      }
    );
  };

  const validateDSDay5Fields = () => {
    values.investigationDetails &&
      values.investigationDetails.forEach((_: any, index: number) => {
        validateField(`investigationDetails[${index}].terminal`);
        validateField(`investigationDetails[${index}].talkedTo`);
        validateField(`investigationDetails[${index}].updates`);
      });
  };

  const updateInitValue = (
    key: string,
    value: string | number | null | undefined
  ) => {
    setInitValues((prevState) => {
      // Update the top-level property
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const completeAndImage = async () => {
    try {
      setPageLoading(true);
      const submitResponse = await SaveOrSubmitDSInvestigationAPI(
        {},
        props.osdNumber?.toString(),
        "submit"
      );
      const { statusNumber, lastStatusNumber } = submitResponse;
      setStatus(statusNumber);
      setLastStatusNumber(lastStatusNumber);
      props.setInvestigationStatus(statusNumber);
      setPageLoading(false);
      setFormKey(Date.now().toString());
      const message =
        "OS&D Investigation notes have been completed successfully & Upload Investigation Image In progress";
      uploadInvestigation();

      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.SUCCESS,
          message,
          Date.now().toString()
        )
      );
    } catch (error) {
      setPageLoading(false);
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          typeof error === "string"
            ? error
            : `OS&D Investigation notes submit failed`,
          Date.now().toString()
        )
      );
    }
  };

  /**
   * Handles saving or submitting the OS&D investigation notes based on the provided action.
   *
   */
  const saveOrSubmit = (action: string, freightLocated: string = "N") => {
    // Exit early if there are invalid fields
    if (invalidFields?.length > 0) return;

    if (activeStep === StepStatusMap[4] && values.pumManifest === 0) {
      document.getElementsByName("pumManifest")[0].focus();
      return;
    }

    setPageLoading(true);

    // Clone values to avoid direct mutation
    let reqParams = JSON.parse(JSON.stringify(values));
    reqParams["freightLocated"] = isFreightLocated ? "Y" : freightLocated;

    // Determine which fields to delete based on current status
    const deleteFields: string[] = [];
    const fieldsMap: { [key: string]: string[] } = {
      [EShortageInvestigationStatus.exceptionEntry]: [
        ...DSDay2Fields,
        ...DSDay3Fields,
        ...DSDay4Fields,
        ...DSDay5Fields,
        ...DSDay10Fields,
        ...DSDay15Fields,
        ...DSDay20Fields,
      ],
      [EShortageInvestigationStatus.day1InProgress]: [
        ...DSDay2Fields,
        ...DSDay3Fields,
        ...DSDay4Fields,
        ...DSDay5Fields,
        ...DSDay10Fields,
        ...DSDay15Fields,
        ...DSDay20Fields,
      ],
      [EShortageInvestigationStatus.day2InProgress]: [
        ...DSDay3Fields,
        ...DSDay4Fields,
        ...DSDay5Fields,
        ...DSDay10Fields,
        ...DSDay15Fields,
        ...DSDay20Fields,
      ],
      [EShortageInvestigationStatus.day3InProgress]: [
        ...DSDay4Fields,
        ...DSDay5Fields,
        ...DSDay10Fields,
        ...DSDay15Fields,
        ...DSDay20Fields,
      ],
      [EShortageInvestigationStatus.day4InProgress]: [
        ...DSDay5Fields,
        ...DSDay10Fields,
        ...DSDay15Fields,
        ...DSDay20Fields,
      ],
      [EShortageInvestigationStatus.day5InProgress]: [
        ...DSDay10Fields,
        ...DSDay15Fields,
        ...DSDay20Fields,
      ],
      [EShortageInvestigationStatus.day10InProgress]: [
        ...DSDay15Fields,
        ...DSDay20Fields,
      ],
      [EShortageInvestigationStatus.day15InProgress]: [...DSDay20Fields],
    };

    const inprogressDay = isFreightLocated ? lastStatusNumber : status;

    // Determine fields to delete based on the freightLocated status and in-progress day
    if (
      reqParams["freightLocated"] === "Y" &&
      (reqParams["investigationDetails"] === null ||
        reqParams["investigationDetails"] === undefined ||
        reqParams["investigationDetails"] === "N")
    ) {
      deleteFields.push("investigationDetails", "investigationDetails");
    }

    if (fieldsMap[inprogressDay]) {
      deleteFields.push(...fieldsMap[inprogressDay]);
    }

    deleteFields.forEach((field: string) => {
      delete reqParams[field];
    });

    if (
      Array.isArray(reqParams.investigationDetails) &&
      (reqParams.investigationDetails.length === 0 ||
        (reqParams.investigationDetails.length === 1 &&
          Object.keys(reqParams.investigationDetails[0]).length === 0))
    ) {
      delete reqParams.investigationDetails;
    }

    if (typeof reqParams["pumManifest"] !== "number") {
      reqParams["pumManifest"] = !isNaN(reqParams["pumManifest"])
        ? parseInt(reqParams["pumManifest"])
        : undefined;
    }

    // Add attachment image URLs if applicable
    if (showSendAPB()) {
      reqParams["attachmentImageUrls"] = selectedImages;
    }

    for (const key of DSYesNoFields) {
      if (reqParams[key] === "") {
        reqParams[key] = undefined;
      }
    }
    
    reqParams = TrimStrings(reqParams);
    // Call API to save or submit the investigation notes
    SaveOrSubmitDSInvestigationAPI(reqParams, props.osdNumber?.toString())
      .then((res) => {
        // Update field values and initialization values based on API response
        const { statusNumber } = res;

        if (res.investigationDetails) {
          res.investigationDetails = (res.investigationDetails || []).map(
            (obj: IDSDay5Investigation) => ({
              talkedTo: obj.talkedTo === null ? "" : obj.talkedTo,
              updates: obj.updates === null ? "" : obj.updates,
              terminal: obj.terminal === null ? "" : obj.terminal,
            })
          );
        } else {
          res.investigationDetails = [{
            talkedTo: undefined,
            updates: undefined,
            terminal: undefined,
          }]
        }

        Object.keys(res).forEach((field: string) => {
          if (
            [
              ...DSDay1Fields,
              ...DSDay2Fields,
              ...DSDay3Fields,
              ...DSDay4Fields,
              ...DSDay5Fields,
              ...DSDay10Fields,
              ...DSDay15Fields,
              ...DSDay20Fields,
            ].includes(field)
          ) {
            const value =
              field === "pumManifest" && res[field] === 0
                ? undefined
                : res[field];
            setFieldValue(field, value);
            updateInitValue(field, value);
          }
        });

        // Determine success message based on action
        setPageLoading(false);
        setFormKey(Date.now().toString());
        let message = "";
        switch (action) {
          case "save":
            message = "OS&D Investigation notes have been saved successfully";
            break;
          case "freightLocated":
            message =
              "OS&D Investigation notes freight located have been successfully";
            break;
          default:
            message =
              "OS&D Investigation notes have been completed successfully & Upload Investigation Image In progress";
            uploadInvestigation();
            break;
        }

        // Dispatch success notification and update state
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.SUCCESS,
            message,
            Date.now().toString()
          )
        );
        if (
          status === statusNumber ||
          statusNumber > EShortageInvestigationStatus.day20InProgress ||
          (status === EShortageInvestigationStatus.exceptionEntry &&
            statusNumber === EShortageInvestigationStatus.day1InProgress)
        ) {
          setFormFieldsDisabled(true);
          setIsEditMode(true);
        } else {
          setIsEditMode(false);
        }
        setStatus(statusNumber);
        setIsFreightLocated(res?.freightLocated === "Y");
        props?.setInvestigationStatus(statusNumber);

        if (res?.freightLocated === "N") {
          setActiveStep(
            StepStatusMap[statusNumber as keyof typeof StepStatusMap]
          );
        } else {
          setLastStatusNumber(res.lastStatusNumber);
        }
        validateForm(values);
        dispatch(isFormValueChanged(false));
      })
      .catch((err) => {
        // Handle error and dispatch error notification
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof err === "string"
              ? err
              : `OS&D Investigation notes ${action} failed`,
            Date.now().toString()
          )
        );
      });
  };

  /**
   * Determines the CSS class for a step based on its index, current status,
   * and whether freight is located.
   *
   * @param index - The index of the step to determine the class for.
   * @returns - The CSS class name for the step.
   */
  const stepClass = (index: number): string => {
    const iStatus = isFreightLocated ? lastStatusNumber : status;

    // Check if the step is complete
    if (
      (isFreightLocated &&
        StepStatusMap[iStatus as keyof typeof StepStatusMap] >= index) ||
      (index === activeStep &&
        StepStatusMap[iStatus as keyof typeof StepStatusMap] > activeStep) ||
      StepStatusMap[iStatus as keyof typeof StepStatusMap] > index ||
      iStatus === EShortageInvestigationStatus.pendingSrApproval ||
      (!isFreightLocated && iStatus === EShortageInvestigationStatus.complete)
    ) {
      return "completeStep";
    }

    // Check if the step is active
    if (
      index === activeStep ||
      StepStatusMap[iStatus as keyof typeof StepStatusMap] === index
    ) {
      return "activeStep";
    }

    // Default case: step is neither complete nor active
    return "";
  };

  /**
   * Returns the day count based on the step index.
   */
  const dayCount = (step: number): number => {
    const val = step + 1;

    if (val >= 7) {
      return val === 7 ? 15 : 20;
    }

    return val === 6 ? 10 : val;
  };

  /**
   * Validates the form on changes to `values` and
   * updates the add button state based on validation results.
   */
  useEffect(() => {
    if (!values) return; // Skip validation if no values
  
    const debouncedValidation = debounce(async () => {
      try {
        const formErrors = await validateForm();
        const investigationDetailsErrors = formErrors.investigationDetails;
  
        if (!investigationDetailsErrors || !investigationDetailsErrors.length) {
          setAddButtonDisabled(false);
        } else {
          validateDSDay5Fields();
          setAddButtonDisabled(true);
        }
      } catch (error) {
        console.error("Error validating form:", error);
        setAddButtonDisabled(true);
      }
    }, 300);
  
    debouncedValidation();
  
    return () => debouncedValidation.cancel();
  }, [values, validateForm, validateDSDay5Fields]);
  
  useEffect(() => {
    const { investigationDetails } = props;
    if (!investigationDetails) return;

    if (
      investigationDetails.pumManifest === 0 &&
      investigationDetails.pumCheck !== "Y"
    ) {
      investigationDetails.pumManifest = undefined;
    }

    const { investigationFreightLocated, investigationLastStatusNumber } =
      investigationDetails;
    setIsFreightLocated(investigationFreightLocated === "Y");
    setLastStatusNumber(investigationLastStatusNumber ?? 1);

    // Prepare the list of all fields to iterate
    const allFields = [
      ...DSDay1Fields,
      ...DSDay2Fields,
      ...DSDay3Fields,
      ...DSDay4Fields,
      ...DSDay10Fields,
      ...DSDay15Fields,
      ...DSDay20Fields,
      ...DSDay5Fields,
    ];

    if((!investigationDetails?.investigationDetails || investigationDetails?.investigationDetails?.length < 1 ) && investigationDetails?.contactTerminals === "Y") {
      investigationDetails.investigationDetails = [{terminal: undefined, talkedTo: undefined, updates:undefined}];
    }
    allFields.forEach((key) => {
      const value =
        key === "pickupManifest" && investigationDetails[key] === 0
          ? undefined
          : investigationDetails[key];

      setFieldValue(key, value);
      updateInitValue(key, value);
    });

    investigationDetails?.investigationDetails?.forEach(
      (obj: IDSDay5Investigation, index: number) => {
        setFieldValue(`investigationDetails[${index}].terminal`, obj.terminal);
        setFieldValue(`investigationDetails[${index}].talkedTo`, obj.talkedTo);
        setFieldValue(`investigationDetails[${index}].updates`, obj.updates);
      }
    );



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.investigationDetails]);

  useEffect(() => {
    if (props.status) {
      const currentDay =
        lastStatusNumber &&
        lastStatusNumber >
          props.investigationDetails?.investigationLastStatusNumber
          ? lastStatusNumber
          : props.investigationDetails?.investigationLastStatusNumber;
      const investigationStatus =
        isFreightLocated ||
        props.investigationDetails?.investigationFreightLocated === "Y"
          ? currentDay
          : props.status;
      setActiveStep(
        StepStatusMap[investigationStatus as keyof typeof StepStatusMap]
      );
      setStatus(
        typeof props.status === "string" ? parseInt(props.status) : props.status
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  useEffect(() => {
    dispatch(isFormValueChanged(!CompareObjects(initValues, values)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initValues]);

  // useEffect for epImagingResponse validation
  useEffect(() => {
    // Check if the epImaging value is 'Y' or 'N' and perform the validation
    if (values.epImaging === "Y" || values.epImaging === "N") {
      isValueExist("epImagingResponse"); // Validate epImagingResponse based on epImaging value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.epImaging]);

  useEffect(() => {
    // Check if the drCheck value is 'Y'
    if (values.drCheck === "Y") {
      isValidDependedField("confirm", "drCheck", StepStatusMap[4]); // Validate confirm based on confirm value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.drCheck]);

  useEffect(() => {
    // Check if the confirm value is 'Y'
    if (values.confirm === "Y") {
      isValidDependedField("confirmSteps", "confirm", StepStatusMap[4]); // Validate confirmSteps based on confirm value
    }
    isValidDependedField("confirm", "drCheck", StepStatusMap[4]); // Validate confirm based on confirm value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.confirm]);

  return (
    <div id="main-ds-investigation">
      <AccordionDetails className="accordion-container">
        <Grid item md={12} xs={12}>
          <FormControl className="custom-group">
            <FormLabel>
              Investigation Day&nbsp;
              {dayCount(activeStep)}
              <sup>*</sup>{" "}
            </FormLabel>
          </FormControl>
        </Grid>
        <ThemeProvider theme={theme}>
          <Stepper nonLinear activeStep={activeStep}>
            {ASInvestigationWizardStep.map((label, index) => (
              <Step key={label} className={stepClass(index)}>
                <StepButton
                  onClick={index === activeStep ? void 0 : handleStep(index)}
                  disableRipple
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        <FormikProvider key={resetTimestamps} value={form}>
          <>
            {activeStep === StepStatusMap[1] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.statusCorrect === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "statusCorrect",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "statusResponse",
                                  e.target.checked
                                    ? values.statusResponse
                                    : null,
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("statusCorrect")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Confirm the delivery status is correct <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.statusCorrect === "Y" && (
                            <TextField
                              name="statusResponse"
                              value={values.statusResponse || ""}
                              fullWidth
                              placeholder="If incorrect what changes were made"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.deliveryCode === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "deliveryCode",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "deliveryCodeResponse",
                                  e.target.checked
                                    ? initValues.deliveryCodeResponse
                                    : null,
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("deliveryCode")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Confirm the delivery code matches the ADE and populated
                        Correctly in the F7 <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.deliveryCode === "Y" && (
                            <TextField
                              name="deliveryCodeResponse"
                              value={values.deliveryCodeResponse || ""}
                              fullWidth
                              placeholder="If Incorrect What Changes Were Made"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminalLabel">
                        Are there EP’s in imaging? <sup>*</sup> &nbsp;
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="epImaging"
                            name="epImaging"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="epImaging"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                isValueExist("epImagingResponse");
                                setFieldValue(
                                  "epImagingResponse",
                                  initValues["epImaging"] === "Y"
                                    ? initValues["epImagingResponse"]
                                    : null,
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.epImaging === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="epImaging"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                isValueExist("epImagingResponse");
                                setFieldValue(
                                  "epImagingResponse",
                                  initValues["epImaging"] === "N"
                                    ? initValues["epImagingResponse"]
                                    : null,
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.epImaging === "N"}
                            />
                          </RadioGroup>
                          {["Y", "N"].includes(values.epImaging || "") && (
                            <FormControl fullWidth size="small">
                              <FormLabel
                                id={
                                  values.epImaging === "Y"
                                    ? "epValid"
                                    : "bpFreight"
                                }
                                className="mb-10"
                              >
                                {values.epImaging === "Y"
                                  ? "Confirm the EP’s are valid"
                                  : "Are there BP’s that show the freight"}
                                <sup>&nbsp;</sup>
                              </FormLabel>
                              <TextField
                                value={values.epImagingResponse || ""}
                                key={values.epImaging}
                                name="epImagingResponse"
                                placeholder="Describe Steps Taken"
                                variant="outlined"
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("epImagingResponse");
                                }}
                                disabled={formFieldsDisabled}
                              />
                            </FormControl>
                          )}

                          {invalidFields.includes("epImagingResponse") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day1}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[3] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.noMovement === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "noMovement",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "dateLastMovement",
                                  e.target.checked
                                    ? initValues.dateLastMovement
                                    : null,
                                  true
                                );
                                setFieldValue(
                                  "noMovementSteps",
                                  e.target.checked
                                    ? initValues.noMovementSteps
                                    : null,
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("noMovement")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Confirm the master shows no movement ? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="destinationTerminal"
                              className="date-label-margin"
                            >
                              Select date of last movement <sup>*</sup>
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={dayjs(values.dateLastMovement ?? "null")}
                                name="dateLastMovement"
                                key={dateKey}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                onChange={(value) => {

                                  if(value?.format("MM/DD/YY") !== "Invalid Date") {
                                    setFieldValue(
                                      "dateLastMovement",
                                      value?.format("MM/DD/YY"),
                                      true
                                    );
                                    setOpen(false);
                                  } else {
                                    setDateKey(Date.now().toString())
                                  }
                                }}
                                format="MM/DD/YYYY"
                                slotProps={{
                                  textField: {
                                    onBlur: handleBlur,
                                    onClick: () => {
                                      setOpen(true);
                                    },
                                    inputProps: {
                                      readOnly: true,
                                    },
                                  },
                                }}
                                disabled={formFieldsDisabled}
                                className="col-md-12"
                              />
                            </LocalizationProvider>
                          )}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="noMovementSteps"
                              className="date-label-margin"
                            >
                              &nbsp;
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <TextField
                              name="noMovementSteps"
                              value={values.noMovementSteps || ""}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EDSInvestigationFields.NoMovementSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.suffixBill === "Y"}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("suffixBill")
                              }
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "suffixBill",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "suffixBillSteps",
                                  e.target.checked
                                    ? initValues.suffixBillSteps
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check if a suffix bill or OD400 was cut to move some or
                        all of the freight <sup>*</sup>
                        <ClickAwayListener
                          key={"day2"}
                          onClickAway={() => handleTooltipClose("day2")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day2")}
                            open={openTooltips["day2"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="If yes, view the AS exception & confirm it was cleared properly. If no, move to next step."
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day2")}
                              onMouseLeave={() => handleTooltipClose("day2")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.suffixBill === "Y" && (
                            <TextField
                              name="suffixBillSteps"
                              value={values.suffixBillSteps || ""}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.SuffixBillSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.SuffixBillSteps
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day2}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Did the driver spot the trailer somewhere? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="spotTrailer"
                            name="spotTrailer"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="spotTrailer"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "freightLeft",
                                  initValues["freightLeft"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.spotTrailer === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="spotTrailer"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("freightLeft", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "freightLeft"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.spotTrailer === "N"}
                            />
                          </RadioGroup>

                          {values.spotTrailer === "Y" && (
                            <>
                              <FormLabel id="destinationTerminal">
                                Was freight left on board? <sup>&nbsp;</sup>
                              </FormLabel>
                              <TextField
                                id="freightLeft"
                                name="freightLeft"
                                fullWidth
                                aria-placeholder="Describe Steps Taken"
                                value={values.freightLeft || ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    "freightLeft",
                                    "spotTrailer",
                                    StepStatusMap[3]
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                className="mt-1"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes("freightLeft") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[4] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("bolCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.bolCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "bolCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "bolSteps",
                                  e.target.checked ? initValues.bolSteps : null,
                                  true
                                );
                                // setFieldValue("pumDiscrepancy", e.target.checked ? initValues.pumDiscrepancy : null, true)
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the BOL to confirm completion and check for
                        discrepancies <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12} className="all-short-entry">
                          {values.bolCheck === "Y" && (
                            <>
                              <FormLabel id="bolSteps" className="mt-0 mb-1">
                                Use info on BOL to look for duplicate
                                pros/billing error <sup>*</sup> &nbsp;
                                <ClickAwayListener
                                  key={"day3"}
                                  onClickAway={() => handleTooltipClose("day3")}
                                >
                                  <Tooltip
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => handleTooltipClose("day3")}
                                    open={openTooltips["day3"] || false}
                                    disableFocusListener
                                    disableTouchListener
                                    title="Option 5 is a good start to look for duplicate pros, but not the only option."
                                  >
                                    <InfoIcon
                                      className="info-icon"
                                      onMouseEnter={() =>
                                        handleTooltipOpen("day3")
                                      }
                                      onMouseLeave={() =>
                                        handleTooltipClose("day3")
                                      }
                                    />
                                  </Tooltip>
                                </ClickAwayListener>
                              </FormLabel>
                              <TextField
                                name="bolSteps"
                                value={values.bolSteps || ""}
                                fullWidth
                                placeholder="Describe Steps taken"
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("bolSteps");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes("bolSteps") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                        <Grid item md={6} xs={12} className="all-short-entry">
                          {values.bolCheck === "Y" && (
                            <>
                              <FormLabel
                                id="bolDiscrepancies"
                                className="mt-0 mb-1"
                              >
                                List any and all discrepancies <sup>*</sup>{" "}
                                &nbsp;
                              </FormLabel>
                              <TextField
                                name="bolDiscrepancies"
                                value={values.bolDiscrepancies || ""}
                                fullWidth
                                placeholder="Describe Steps taken"
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("bolDiscrepancies");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes("bolDiscrepancies") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="drCheck">
                        Is there is a DR? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="drCheck"
                            name="drCheck"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                isValidDependedField(
                                  "confirm",
                                  "drCheck",
                                  StepStatusMap[4]
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("confirm", null, true);
                                setFieldValue("confirmSteps", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) =>
                                      field !== "confirmSteps" &&
                                      field !== "confirm"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "N"}
                            />
                          </RadioGroup>

                          {values.drCheck === "Y" && (
                            <FormControl className="radio-group question-group ">
                              <FormLabel>
                                <FormControlLabel
                                  className="checkbox-shoartage"
                                  control={
                                    <Checkbox
                                      disableRipple
                                      checked={values.confirm === "Y"}
                                      onBlur={() => {
                                        isValidDependedField(
                                          "confirmSteps",
                                          "confirm",
                                          StepStatusMap[4]
                                        );
                                        isValidDependedField(
                                          "confirm",
                                          "drCheck",
                                          StepStatusMap[4]
                                        );
                                      }}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setFieldValue(
                                          "confirm",
                                          e.target.checked ? "Y" : "N"
                                        );

                                        setFieldValue(
                                          "confirmSteps",
                                          e.target.checked
                                            ? initValues.confirmSteps
                                            : null,
                                          true
                                        );
                                        isValidDependedField(
                                          "confirm",
                                          "drCheck",
                                          StepStatusMap[4]
                                        );
                                        isValidDependedField(
                                          "confirmSteps",
                                          "confirm",
                                          StepStatusMap[4]
                                        );
                                      }}
                                      disabled={
                                        formFieldsDisabled ||
                                        isControlDisabled("confirm")
                                      }
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 20 },
                                      }}
                                    />
                                  }
                                  label=""
                                />
                                Confirm the master shows no movement ?{" "}
                                <sup>*</sup>
                                {invalidFields.includes("confirm") && (
                                  <>
                                    <br />
                                    <span className="form-error">
                                      {EASInvestigationFieldsErrorMessage.day3}
                                    </span>
                                  </>
                                )}
                              </FormLabel>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  md={12}
                                  xs={12}
                                  className="all-short-entry"
                                >
                                  {values.confirm === "Y" && (
                                    <TextField
                                      name="confirmSteps"
                                      value={values.confirmSteps || ""}
                                      fullWidth
                                      placeholder="Describe Steps Taken"
                                      inputProps={{ maxLength: 60 }}
                                      onChange={handleChange}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        isValidDependedField(
                                          "confirmSteps",
                                          "confirm",
                                          StepStatusMap[4]
                                        );
                                      }}
                                      disabled={formFieldsDisabled}
                                      variant="outlined"
                                    />
                                  )}
                                  {invalidFields.includes("confirmSteps") &&
                                    values.confirm === "Y" && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day3
                                        }
                                      </span>
                                    )}
                                </Grid>
                              </Grid>
                            </FormControl>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("pumCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.pumCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "pumCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "pumManifest",
                                  e.target.checked
                                    ? initValues.pumManifest
                                    : null,
                                  true
                                );
                                // setFieldValue("pumDiscrepancy", e.target.checked ? initValues.pumDiscrepancy : null, true)
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the PUM (Pickup manifest) for discrepancies,
                        shipper load and count information, and proper reporting
                        process <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel
                                id="destinationTerminal"
                                className="mt-0 mb-1"
                              >
                                Pickup Manifest # <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="pumManifest"
                                value={values.pumManifest || ""}
                                fullWidth
                                placeholder="Enter Pickup Manifest #"
                                type="number"
                                onKeyDown={HandleKeyDown}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  HandleNumberMaxLength(e, 9);
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("pumManifest");
                                }}
                                error={!!(values.pumManifest === 0)}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes("pumManifest") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                          {values.pumManifest === 0 &&
                            values.pumCheck === "Y" && (
                              <span className="form-error">
                                Invalid Pickup Manifest # Number
                              </span>
                            )}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel
                                id="destinationTerminal"
                                className="mt-0 mb-1"
                              >
                                List any and all discrepancies <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="pumDiscrepancy"
                                value={values.pumDiscrepancy || ""}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("pumDiscrepancy");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes("pumDiscrepancy") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="shipperLoad">
                        Was this a shipper load and count? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="shipperLoad"
                            name="shipperLoad"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "shipperDiscrepancy",
                                  initValues["shipperDiscrepancy"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("shipperDiscrepancy", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "shipperDiscrepancy"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "N"}
                            />
                          </RadioGroup>

                          {values.shipperLoad === "Y" && (
                            <>
                              <FormLabel
                                id="shipperDiscrepancy"
                                className="mb-1"
                              >
                                Were there any discrepancies? If so, were they
                                properly reported to the shipper?<sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="shipperDiscrepancy"
                                value={values.shipperDiscrepancy || ""}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("shipperDiscrepancy");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}

                          {invalidFields.includes("shipperDiscrepancy") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("lineHaulManifest")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.lineHaulManifest === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "lineHaulManifest",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "numbersReview",
                                  e.target.checked
                                    ? initValues.numbersReview
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check all linehaul manifests – movement and
                        abnormalities. <sup>*</sup> &nbsp;
                        <ClickAwayListener
                          key={"day4"}
                          onClickAway={() => handleTooltipClose("day4")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day4")}
                            open={openTooltips["day4"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Get manifest numbers from the movement screen (F10)"
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day4")}
                              onMouseLeave={() => handleTooltipClose("day4")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.lineHaulManifest === "Y" && (
                            <TextField
                              name="numbersReview"
                              value={values.numbersReview || ""}
                              fullWidth
                              placeholder="List all Manifest number reviewed"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist("numbersReview");
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes("numbersReview") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("interviewWorkers")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.interviewWorkers === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "interviewWorkers",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "workerSteps",
                                  e.target.checked
                                    ? initValues.workerSteps
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Interview the dock worker(s) about movement
                        abnormalities. <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.interviewWorkers === "Y" && (
                            <TextField
                              name="workerSteps"
                              value={values.workerSteps || ""}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.WorkerSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EDSInvestigationFields.WorkerSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call consignee to confirm what was ordered and received
                      (if anything)
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="consigneePhone"
                        value={values.consigneePhone || ""}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ConsigneePhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.ConsigneePhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Consignee Name <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="consigneeName"
                        value={values.consigneeName || ""}
                        fullWidth
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ConsigneeName);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Consignee Name"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.ConsigneeName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Is the consignee still short? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="destinationTerminal"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="consigneeShort"
                              // onChange={handleChange}
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "itemNumbers",
                                  initValues["itemNumbers"],
                                  true
                                );
                                setFieldValue(
                                  "orderReceived",
                                  initValues["orderReceived"],
                                  true
                                );
                                setFieldValue(
                                  "freightLabeled",
                                  initValues["freightLabeled"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="consigneeShort"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("itemNumbers", null, true);
                                setFieldValue("orderReceived", null, true);
                                setFieldValue("freightLabeled", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) =>
                                      ![
                                        "itemNumbers",
                                        "orderReceived",
                                        "freightLabeled",
                                      ].includes(field)
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "N"}
                            />
                          </RadioGroup>

                          {values.consigneeShort === "N" && (
                            <Snackbar
                              className="snackbar-error flex-start"
                              anchorOrigin={{ vertical, horizontal }}
                              open={true}
                              onClose={handleClose}
                              message="Note: If the consignee is no longer short, the investigation is considered complete once we obtain written confirmation and/or a signed DR."
                              key={vertical + horizontal}
                            />
                          )}

                          {values.consigneeShort === "Y" && (
                            <>
                              <FormLabel className="mb-10">
                                What are the item numbers received and still
                                missing? <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="itemNumbers"
                                value={values.itemNumbers || ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EDSInvestigationFields.ItemNumbers,
                                    EDSInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EDSInvestigationFields.ItemNumbers
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EDSInvestigationFields.ItemNumbers
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel className="mb-10">
                                What was ordered and received for each PO?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="orderReceived"
                                value={values.orderReceived || ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EDSInvestigationFields.OrderReceived,
                                    EDSInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EDSInvestigationFields.OrderReceived
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EDSInvestigationFields.OrderReceived
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel className="mb-10">
                                Was the freight received labeled for them?
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="freightLabeled"
                                value={values.freightLabeled || ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EDSInvestigationFields.FreightLabeled,
                                    EDSInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EDSInvestigationFields.FreightLabeled
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EDSInvestigationFields.FreightLabeled
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call Shipper to confirm what was shipped, how it was
                      packaged and the value?
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shipperPhone"
                        value={values.shipperPhone || ""}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShipperPhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.ShipperPhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Shipper Name <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shipperName"
                        value={values.shipperName || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShipperName);
                        }}
                        disabled={formFieldsDisabled}
                        placeholder="Enter Shipper Name"
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.ShipperName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What are the details of what shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shippedDetails"
                        value={values.shippedDetails || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShippedDetails);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.ShippedDetails
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Was this short shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shortShipped"
                        value={values.shortShipped || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShortShipped);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.ShortShipped
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What is the value of the product? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="productValue"
                        value={values.productValue || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ProductValue);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.ProductValue
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Were there multiple shipments for the same consignee
                        that day? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="multiShipments"
                        value={values.multiShipments || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("multiShipments");
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes("multiShipments") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call driver about delivery
                    </h3>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Who did you talk to? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="callDriver"
                        value={values.callDriver || ""}
                        fullWidth
                        inputProps={{ maxLength: 60 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("callDriver");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Driver Name"
                      />
                    </FormControl>
                    {invalidFields.includes("callDriver") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What was delivered? Does it match the ADE? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="adeDelivery"
                        value={values.adeDelivery || ""}
                        fullWidth
                        inputProps={{ maxLength: 60 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("adeDelivery");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {invalidFields.includes("adeDelivery") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Could the freight be in the nose? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="freightNose"
                        value={values.freightNose || ""}
                        fullWidth
                        inputProps={{ maxLength: 60 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.FreightNose);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EDSInvestigationFields.FreightNose
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Was there anything out of the ordinary about this
                        delivery? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="outOfOrdinary"
                        value={values.outOfOrdinary || ""}
                        fullWidth
                        inputProps={{ maxLength: 60 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("outOfOrdinary");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {invalidFields.includes("outOfOrdinary") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[5] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("check7818")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818",
                                  e.target.checked
                                    ? initValues.details7818
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply<sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818 === "Y" && (
                            <TextField
                              id={`details7818`}
                              name="details7818"
                              value={values.details7818 || ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.Details7818
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              className=""
                              placeholder="Provide details of your search"
                              inputProps={{ maxLength: 60 }}
                              variant="outlined"
                            />
                          )}

                          {invalidFields.includes(
                            EDSInvestigationFields.Details7818
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day4}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Check cameras at DT and report findings <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="cameraFindings"
                        value={values.cameraFindings || ""}
                        fullWidth
                        inputProps={{ maxLength: 60 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("cameraFindings");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {invalidFields.includes("cameraFindings") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day4}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[6] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("callSameDays")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.callSameDays === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "callSameDays",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "sameDayPros",
                                  e.target.checked
                                    ? initValues.sameDayPros
                                    : null,
                                  true
                                );
                                // setFieldValue("pumDiscrepancy", e.target.checked ? initValues.pumDiscrepancy : null, true)
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Call same days <sup>*</sup>
                        <ClickAwayListener
                          key={"day4"}
                          onClickAway={() => handleTooltipClose("day4")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day4")}
                            open={openTooltips["day4"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Use F17 from FB inquiry screen for pickup info"
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day4")}
                              onMouseLeave={() => handleTooltipClose("day4")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.callSameDays === "Y" && (
                            <>
                              <FormLabel id="sameDayPros" className="mt-0 mb-1">
                                List all same day Pro’s and their delivery info{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="sameDayPros"
                                value={values.sameDayPros || ""}
                                fullWidth
                                placeholder="List Pro's and Delivery Info"
                                inputProps={{ maxLength: 32 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("sameDayPros");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes("sameDayPros") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day5}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("contactTerminals")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.contactTerminals === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "contactTerminals",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "investigationDetails",
                                  e.target.checked
                                    ? _.isArray(
                                        initValues.investigationDetails
                                      ) &&
                                      initValues.investigationDetails.length > 0
                                      ? initValues.investigationDetails
                                      : DSInvestigationInitValue.investigationDetails
                                    : [],
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Contact the terminals that handled the freight to find
                        leads and check cameras if applicable <sup>*</sup>
                        <ClickAwayListener
                          key={"day6"}
                          onClickAway={() => handleTooltipClose("day6")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day6")}
                            open={openTooltips["day6"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Use movement screen (F10) to locate terminals."
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day6")}
                              onMouseLeave={() => handleTooltipClose("day6")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                    </FormControl>
                  </Grid>

                  {values.contactTerminals === "Y" && (
                    <Grid  key={formKey}
                      container
                      spacing={2}
                      className="filter-margin custom-container-pos"
                    >
                      <FieldArray name="investigationDetails">
                        {({ push, remove }) => (
                          <>
                            {_.get(values, "investigationDetails", []).map(
                              (
                                investigationD: IDSDay5Investigation,
                                index: number
                              ) => (
                                <>
                                  <React.Fragment key={index}>
                                    <Grid item md={12} xs={12} className="pt-0">
                                      <h3 className="header-text mb-0">
                                        Requests for terminal assistance:{" "}
                                        {index + 1}
                                      </h3>
                                    </Grid>

                                    <Grid item md={3} xs={12} className="pt-0">
                                      <FormControl className="radio-group question-group ">
                                        <FormLabel>
                                          TID <sup>*</sup>
                                        </FormLabel>
                                        <TextField
                                          id={`tid-${index}`}
                                          name={`investigationDetails[${index}].terminal`}
                                          value={_.get(
                                            values,
                                            `investigationDetails[${index}].terminal`,
                                            ""
                                          )}
                                          onChange={handleChange}
                                          onBlur={(e) => {
                                            handleBlur(e);
                                            isValueExist("terminal", index);
                                          }}
                                          disabled={formFieldsDisabled}
                                          fullWidth
                                          className="mt-1"
                                          placeholder="Enter TID"
                                          inputProps={{ maxLength: 5 }}
                                          variant="outlined"
                                        />
                                      </FormControl>
                                      {invalidFields.includes(
                                        "terminal" + index
                                      ) && (
                                        <span className="form-error">
                                          {
                                            EASInvestigationFieldsErrorMessage.day5
                                          }
                                        </span>
                                      )}
                                    </Grid>

                                    <Grid item md={3} xs={12} className="pt-0">
                                      <FormControl className="radio-group question-group ">
                                        <FormLabel>
                                          Who did you talk to?<sup>*</sup>
                                        </FormLabel>
                                        <TextField
                                          id={`talkedTo-${index}`}
                                          name={`investigationDetails[${index}].talkedTo`}
                                          value={_.get(
                                            values,
                                            `investigationDetails[${index}].talkedTo`,
                                            ""
                                          )}
                                          onChange={handleChange}
                                          onBlur={(e) => {
                                            handleBlur(e);
                                            isValueExist("talkedTo", index);
                                          }}
                                          disabled={formFieldsDisabled}
                                          fullWidth
                                          className="mt-1"
                                          placeholder="Enter Who did you talk to"
                                          inputProps={{ maxLength: 32 }}
                                          variant="outlined"
                                        />
                                      </FormControl>
                                      {invalidFields.includes(
                                        "talkedTo" + index
                                      ) && (
                                        <span className="form-error">
                                          {
                                            EASInvestigationFieldsErrorMessage.day5
                                          }
                                        </span>
                                      )}
                                    </Grid>

                                    <Grid item md={6} xs={12} className="pt-0">
                                      <FormControl className="radio-group question-group ">
                                        <FormLabel>
                                          Provide updates and/or information
                                          from the terminal <sup>*</sup>
                                        </FormLabel>
                                        <TextField
                                          id={`updates-${index}`}
                                          name={`investigationDetails[${index}].updates`}
                                          value={_.get(
                                            values,
                                            `investigationDetails[${index}].updates`,
                                            ""
                                          )}
                                          onChange={handleChange}
                                          onBlur={(e) => {
                                            handleBlur(e);
                                            isValueExist("updates", index);
                                          }}
                                          disabled={formFieldsDisabled}
                                          fullWidth
                                          className="mt-1"
                                          placeholder="Enter Details"
                                          inputProps={{ maxLength: 60 }}
                                          variant="outlined"
                                        />
                                      </FormControl>
                                      {invalidFields.includes(
                                        "updates" + index
                                      ) && (
                                        <span className="form-error">
                                          {
                                            EASInvestigationFieldsErrorMessage.day5
                                          }
                                        </span>
                                      )}
                                    </Grid>
                                  </React.Fragment>
                                </>
                              )
                            )}
                            <Grid item md={12} xs={12} className=" ">
                              <Snackbar
                                className="snackbar-error flex-start justify-content-left"
                                anchorOrigin={{ vertical, horizontal }}
                                open={true}
                                onClose={handleClose}
                                message="Note | Review strip manifests in imaging prior to contacting the terminal"
                                key={vertical + horizontal}
                              />
                            </Grid>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              className="btn-group-submit"
                            >
                              {!(
                                formFieldsDisabled ||
                                status === EShortageInvestigationStatus.complete
                              ) && (
                                <>
                                  {values.investigationDetails?.length! < 6 && (
                                    <Button
                                      onClick={
                                        addButtonDisabled
                                          ? void 0
                                          : () => {
                                              push({
                                                terminal: undefined,
                                                talkedTo: undefined,
                                                updates: undefined,
                                              });
                                              addRemove();
                                            }
                                      }
                                      variant="contained"
                                      className={`entry ml ${
                                        addButtonDisabled
                                          ? "disable-btn disable"
                                          : ""
                                      }`}
                                    >
                                      Add
                                    </Button>
                                  )}
                                  {values.investigationDetails?.length !==
                                    1 && (
                                    <Button
                                      onClick={() => {
                                        remove(
                                          values.investigationDetails?.length! -
                                            1
                                        );
                                        addRemove("remove");
                                      }}
                                      variant="contained"
                                      className="clear-btn mr-0 ml"
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[7] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("followUp")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.followUp === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "followUp",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "followUpDetails",
                                  e.target.checked
                                    ? values.followUpDetails
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Follow up on all leads/request from previous days{" "}
                        <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.followUp === "Y" && (
                            <TextField
                              name="followUpDetails"
                              value={values.followUpDetails || ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.FollowUpDetails
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              inputProps={{ maxLength: 60 }}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EDSInvestigationFields.FollowUpDetails
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day10}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[8] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("check7818_2")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818_2 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818_2",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818_2",
                                  e.target.checked
                                    ? values.details7818_2
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818_2 === "Y" && (
                            <TextField
                              name="details7818_2"
                              value={values.details7818_2 || ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.Details7818_2
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              inputProps={{ maxLength: 60 }}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EDSInvestigationFields.Details7818_2
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day15}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Is this high value or high quantity? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="highValue"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="highValue"
                              checked={values.highValue === "Y"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="highValue"
                              checked={values.highValue === "N"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* {showSendAPB() && ( */}
                <div
                  className={`${
                    showSendAPB() ? "" : "hide-me"
                  } another-class-name`}
                >
                  <div className="MuiTypography-root header-notice justify-content">
                    Since you've chosen the "high value/quantity" option, an APB
                    will be issued. Please remove any photos you'd prefer not to
                    include in the APB email.
                  </div>
                  <SendAPBImageList
                    osdNumber={props.osdNumber}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    handleImageSelect={handleImageSelect}
                    isSendAPBScreen={false}
                  />
                  <br />
                </div>
                {/* )} */}
              </div>
            )}

            {activeStep === StepStatusMap[9] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("finalReCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.finalReCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "finalReCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "detailsOfSearch",
                                  e.target.checked
                                    ? initValues.detailsOfSearch
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Final re-check of all leads/requests/overages to confirm
                        the freight cannot be located at this time <sup>*</sup>
                      </FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12} className="all-short-entry">
                    {values.finalReCheck === "Y" && (
                      <TextField
                        id={`detailsOfSearch`}
                        name="detailsOfSearch"
                        value={values.detailsOfSearch || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          // isValueExist("detailsOfSearch");
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        className=""
                        placeholder="Provide details of your search"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        </FormikProvider>
        <Grid item md={12} xs={12} className="btn-group-submit">
          {formFieldsDisabled &&
            status !== EShortageInvestigationStatus.complete && (
              <>
                <Button
                  variant="contained"
                  onClick={enableEditing}
                  className="assign ml mr-0"
                >
                  Edit
                </Button>
              </>
            )}
          {!formFieldsDisabled && (
            <>
              <Button
                onClick={() => reset()}
                variant="contained"
                className="clear-btn mr-0 ml"
                type="button"
              >
                {isEditMode ? "Cancel" : "Clear"}
              </Button>
              <Button
                variant="contained"
                onClick={
                  invalidFields?.length > 0
                    ? void 0
                    : () => {
                        saveOrSubmit("save");
                      }
                }
                className={
                  invalidFields?.length > 0
                    ? "disable-btn disable entry ml"
                    : "entry ml"
                }
              >
                Save
              </Button>
            </>
          )}
          {formFieldsDisabled &&
            !isFreightLocated &&
            StepStatusMap[status as keyof typeof StepStatusMap] ===
              activeStep &&
            [
              EShortageInvestigationStatus.complete,
              EShortageInvestigationStatus.exceptionEntry,
              EShortageInvestigationStatus.pendingSrApproval,
            ].indexOf(status) === -1 && (
              <Button
                onClick={() => saveOrSubmit("freightLocated", "Y")}
                variant="contained"
                className="entry ml"
              >
                Freight Located
              </Button>
            )}

          {formFieldsDisabled &&
            isPrivilegedUser &&
            StepStatusMap[
              (isFreightLocated
                ? lastStatusNumber
                : status) as keyof typeof StepStatusMap
            ] === activeStep &&
            status === EShortageInvestigationStatus.pendingSrApproval && (
              <Button
                onClick={completeAndImage}
                variant="contained"
                className="entry ml"
              >
                Complete & Image
              </Button>
            )}
        </Grid>
      </AccordionDetails>
      <Loader pageLoader={pageLoading}></Loader>
      {ASInvestigationWizardStep.map((day: string) => (
        <div key={day} className="hide-me" id={`investigation-${day}`}>
          <DSInvestigationTemplate
            currentDay={day}
            investigationValues={values}
            osdNumber={props.osdNumber}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(DsInvestigation); // Prevent unnecessary re-renders
