import { ICheckDigitPro } from "../models/osd";

export enum ETab {
  dashboard = 0,
  overages = 1,
  myAssignments = 2,
  shortages = 3,
  maintenance = 9,
  unauthorized = 10
}

export enum DType {
  pro = "pro",
  od400 = "od400",
  assignTo = "assignTo",
  assignBy = "assignBy",
  entryBy = "entryBy",
  osd = "osd",
  shipperZip = "shipperZip",
  shipperCity = "shipperCity",
  consigneeCity = "consigneeCity",
  consigneeZip = "consigneeZip",
  commodity = "commodity",
}

// type of page object for data grid pagination

export type TPageObject = {
  page: number;
  pageSize: number;
};

export type TMasterSearchParameter = {
  search: string;
  page: number;
  limit: number
}

export type TGridSortModel = {
  field?: string;
  sort?: "asc" | "desc" | null | undefined | "ASC" | "DESC";
};


export enum EUploadKey {
  od400Pro = "od400ProNumber",
  pro = "proNumber"
}

export const APP: any = {
  showTerminalPickerPage: [ETab.dashboard, ETab.overages, ETab.shortages],
  showHeaderSearchPage: [ETab.myAssignments, ETab.overages, ETab.shortages],
  overageRowSelectable: {key: "investigationStatus", value: "complete"},
  overage: {
    id: "osdNumber",
    rowsPerPageOptions: [25, 50, 100],
    userAPILimit: 10,
    userAPIpageLimit: 1,
    pageObject: { pageSize: 100, page: 0 },
    searchMaxLength: 9,
    sortModel: { field: "entryDate", sort: "desc" },
    pageSize: 100,
    initialPage: 1,
    imageType: ["EL", "EP", "WP", "BP", "TK", "FX", "OD"],
    sendAPBImageType: ["BP", "OD", "WP", "EL", "WX"],
    shortageSendAPBImageType: ["BP", "OD", "WP", "EL", "WX", "EP", "DR"],
    gridHeading: {
      assignedToName: "Assigned to",
      assignedByName: "Assigned by",
      investigationStatus: "Investigation status",
      osdNumber: "Exception #",
      osdType: "Exception code",
      entryDate: "Entry date",
      entryBy: "Entry User",
      proNumber: "Pro #",
      od400ProNumber: "OD400 Pro #",
      origin: "Origin terminal",
      destination: "Destination terminal",
      reporting: "Reporting terminal",
      commodity: "Commodity",
      description: "Description",
      viewDescription: "View",
      valueRange: "Value Range",
      value1k: "Value",
      lastManifest: "Last Manifest #",
      trailer: "Trailer #",
      photos: "Photos",
    },
    sortingDisabledColumns: [
      "photos",
      "viewDescription",
      "commodity",
      "description",
    ],
    overageDetailsColumns: [
      {
        headerName: "Exception #",
        field: "osdNumber",
        sortable: false,
        flex: 1,
      },
      { headerName: "Type", field: "osdType", sortable: false, flex: 1 },
      { headerName: "Origin", field: "origin", sortable: false, flex: 1 },
      { headerName: "Dest", field: "destination", sortable: false, flex: 1 },
      { headerName: "Rept", field: "reporting", sortable: false, flex: 1 },
      { headerName: "Reg", field: "regionTerminal", sortable: false, flex: 1 },
      { headerName: "PRO #", field: "proNumber", sortable: false, flex: 1 },
      { headerName: "Trailer", field: "trailer", sortable: false, flex: 1 },
      { headerName: "Manifest", field: "manifest", sortable: false, flex: 1 },
      {
        headerName: "Entry Date",
        field: "entryDate",
        sortable: false,
        flex: 1,
      },
      {
        headerName: "Entry Time",
        field: "entryTime",
        sortable: false,
        flex: 1,
      },
      { headerName: "Entry By", field: "entryBy", sortable: false, flex: 1 },
      {
        headerName: "Sum of Total Value",
        field: "value1k",
        sortable: false,
        flex: 1,
      },
      { headerName: "Haz Mat", field: "hazmat", sortable: false, flex: 1 },
    ],

    overagesShipperDetails: [
      { headerName: "Shipper", field: "shipperName", sortable: false, flex: 1 },
      {
        headerName: "Address",
        field: "shipperAddress",
        sortable: false,
        flex: 1,
      },
      { headerName: "city", field: "shipperCity", sortable: false, flex: 1 },
      { headerName: "State", field: "shipperState", sortable: false, flex: 1 },
      { headerName: "Zip", field: "shipperZip", sortable: false, flex: 1 },
    ],

    overageConsigneeDetails: [
      {
        headerName: "Consignee",
        field: "consigneeName",
        sortable: false,
        flex: 1,
      },
      {
        headerName: "Address",
        field: "consigneeAddress",
        sortable: false,
        flex: 1,
      },
      { headerName: "city", field: "consigneeCity", sortable: false, flex: 1 },
      {
        headerName: "State",
        field: "consigneeState",
        sortable: false,
        flex: 1,
      },
      { headerName: "Zip", field: "consigneeZip", sortable: false, flex: 1 },
    ],
    manifestProColumns: [
      {
        field: "proNumber",
        headerName: "Pro Number",
        sortable: false,
        flex: 1,
      },
      // { field: "exln", headerName: "EXLN", sortable: false, flex: 1 },
      {
        field: "hazmat",
        headerName: "Haz Mat",
        sortable: false,
        minWidth: 20,
        flex: 1,
      },
      { field: "shipper", headerName: "Shipper", sortable: false, flex: 1 },
      { field: "consignee", headerName: "Consignee", sortable: false, flex: 1 },
      // { field: "payer", headerName: "Payer (P/C/3)", sortable: false, flex: 1 },
      { field: "destination", headerName: "DEST", sortable: false, flex: 1 },
      { field: "handlingUnits", headerName: "H/U", sortable: false, flex: 1 },
      { field: "totalPieces", headerName: "Pieces", sortable: false, flex: 1 },
      { field: "weight", headerName: "WGT", sortable: false, flex: 1 },
      { field: "cubicFeet", headerName: "CUFT", sortable: false, flex: 1 },
    ],
  },
  OverageEntryFields: [
    "proNumber",
    "reporting",
    "origin",
    "destination",
    "shipperAccount",
    "consigneeAccount",
    "type",
    "shipper",
    "shipperAddress",
    "shipperCity",
    "shipperState",
    "shipperZip",
    "consigneeCity",
    "consigneeState",
    "consigneeZip",
    "consignee",
    "consigneeAddress",
    "od400Completed",
    "poNumber",
    "od400Manifest",
    "shrinkWrap",
    "itemCartonModelSerialNumber",
    "weight",
    "manufacturerBrand",
    "billNumber",
    "snNumber",
    "hazmat",
    "trailer",
    "bay",
    "commodity",
    "package",
    "pieces",
    "od400ProNumber",
    "handlingUnits",
    "valueRange",
    "dimension",
    "barcode",
    "additionalDescription",
    "heatNumbers",
    "colorCode",
    "agentInterline",
    "trailerOrBay",
    "value1k",
  ],

  NBInvestigationFields: [
    "osdType",
    "od400Check",
    "od400CheckResponse",
    "markingsCheck",
    "markingsCheckResponse",
    "thCheck",
    "thCheckResponse",
    "mfCheck",
    "mfCheckResponse",
    "osAndDCheck",
    "osAndDCheckResponse",
    "googleCheck",
    "googleCheckResponse",
    "unmanifestedCheck",
    "unmanifestedCheckResponse",
    "undeliveredCheck",
    "undeliveredCheckResponse",
    "customerDeliveryCheck",
    "customerDeliveryCheckResponse",
    "undelInvCheck",
    "undelInvCheckResponse",
    "apbCheck",
    "apbCheckResponse",
    "emailCheck",
    "emailCheckResponse",
  ],
  OVInvestigationFields: [
    "osdType",
    "od400Check",
    "od400CheckResponse",
    "billOfLadingCheck",
    "billOfLadingCheckResponse",
    "drCheck",
    "drCheckResponse",
    "psCheck",
    "psCheckResponse",
    "consigneeCheck",
    "consigneeCheckResponse",
    "shipmentCheck",
    "shipmentCheckResponse",
  ],

  shortage: {
    gridHeading: {
      assignedToName: "Assigned to",
      investigationStatus: "Investigation status",
      osdNumber: "Exception #",
      osdType: "Exception code",
      entryDate: "Entry date",
      entryBy: "Entry User",
      proNumber: "Pro #",
      origin: "Origin terminal",
      destination: "Destination terminal",
      reporting: "Reporting terminal",
      commodity: "Commodity",
      description: "Description",
      viewDescription: "View",
      valueRange: "Value Range",
      value1k: "Value",
      lastManifest: "Last confirmed manifest #",
      manifest: "Manifest Short Reported On",
      trailer: "Trailer #",
      photos: "Photos",
    },
    sortingDisabledColumns: [
      "photos",
      "viewDescription",
      "commodity",
      "description",
    ],
  },

  documentColumns: {
    name: "File Name",
    docType: "Doc Type",
    id: "Delete",
  },
};

export const CheckDigitFields: Array<keyof ICheckDigitPro> = [
  "origin",
  "destination",
  "consignee",
  "consigneeZip",
  "shipper",
  "shipperCity",
  "shipperState",
  "shipperZip",
  "consigneeAccount",
  "consigneeAddress",
  "consigneeCity",
  "consigneeState",
  "shipperAccount",
  "shipperAddress",
]

export enum EFormAction {
  edit = "edit",
  create = "create",
  typeChanged = "typeChanged",
}

export enum EAutoSearchFieldName {
  name = "Name",
  od400 = "OD400 PRO #",
  pro = "PRO #",
  osdNumber = "Exception #",
  username = "Username",
  zip = "Zip",
  city = "City",
}

export enum EDataGridEvent {
  onSort = "onSort",
  pageChange  = "pageChange",
  assigned = "assigned"
}

export enum AppURLs {
  dashboard = "/dashboard",
  overages = "/overages",
  overageEntry = "/overage/entry",
  editOverageEntry = "/overage/entry/:osdNumber",
  viewDescription = "/exception/:osdNumber",
  manifestDetails = "/manifest/:id",
  myAssignments = "/myAssignments",
  shortages = "/shortages",
  sendAPB = "/exception/:osdNumber/apb/send",
  shortageEntry = "/all-shortages/entry",
  editASEntry = "/all-shortages/entry/:osdNumber",
  editDSEntry = "/delivered-short/entry/:osdNumber",
  editBNF = "/bnf/entry/:osdNumber"
}

export const OverageOsdType = ["OV", "NB"];
export const ShortageOsdType = ["AS", "DS", "BNF"];
export const enum EOsdType {
  ov = "OV",
  nb = "NB",
  as = "AS",
  ds = "DS",
  bnf = "BNF",
}

