import axios from "axios";
import { ApiType } from "./Types";
import { getUrl } from "./getURL";

/**
 * GET Pro check digit detail API call using Axios
 */

export const deploymentStatusApi = async () => {
  try {
    const response = await axios.get(`${getUrl(ApiType.DEPLOYMENT_STATUS)}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response;
  } catch (error) {
    throw "error";
  }
};
