import axios from "axios";
import * as _ from "lodash";
import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";

/**
 * CRETE API call using Axios
 * @returns - Create Delivered Short Investigation save and submit API and return same object
 */

export const SaveOrSubmitDSInvestigationAPI = async (params: any, osdNumber: string, action: string = "save") => {
  try {
    const response = await axios.post(`${getUrl(ApiType.SAVE_OR_SUBMIT_DS_INVESTIGATION).replace(":osdId", osdNumber)}/${action}`, params, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.saveOrSubmitDSInvestigation;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message = _.get(error, "response.data.errors[0].message") || _.get(error, "errors[0].message");
    }
    throw message;
  }
};
