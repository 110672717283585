import { useEffect, useMemo, useState } from "react";
import "./exceptions.css";
import DynamicGrid from "../../components/dynamicGrid/DynamicGrid";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../AppState";
import { FooterTabPosition } from "../../AppState/footerTabPosition";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { DateHelper } from "../../helpers/date.helper";
import {
  DetermineTab,
  FilterCriteriaValue,
  Username,
} from "../../helpers/utils.helper";
import { useLocation } from "react-router-dom";
import { getExceptionList } from "../../API/getExceptionList";
import {
  filterFromSelector,
  pageSelector,
  sortSelector,
} from "../../AppState/selectors";
import { ETab, OverageOsdType, ShortageOsdType } from "../../constants/app";
import {
  IException,
} from "../../models/osd";
import {
  IFilterForm,
  TTerminal,
} from "../../AppState/overagesFilterFormActions";

function Exceptions() {
  const dispatch = useDispatch();
  const location = useLocation();
  const tab = DetermineTab(location.pathname);

  const currentTerminalValue = useSelector(
    (state: AppState) => state.activeTerminal
  );
  const terminals = useSelector((state: AppState) => state.terminalMaster);
  const tabPosition = useSelector((state: AppState) => state.tabPosition);
  const currentTabPosition = useSelector(
    (state: AppState) => state.tabPosition
  );
  const pageObject = useSelector(pageSelector);
  const sortModel = useSelector(sortSelector);
  const filterParams = useSelector(filterFromSelector);

  const [rowCount, setRowCount] = useState(0);
  const [exceptions, setExceptions] = useState<IException[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState("");

  // Helper function to generate terminal list
  const getTerminalList = (
    currentTerminal: TTerminal,
    allTerminals: TTerminal[]
  ) => {
    // If currentTerminal?.region is undefined or null, return all terminals with the same region
    if (!currentTerminal?.region) {
      return allTerminals
        .filter((t) => t.region === currentTerminal.value)
        .map((t) => t.value);
    }

    // Otherwise, return an array containing only the current terminal's value
    return [currentTerminal.value];
  };

  // Helper function to get exception type
  const getExceptionType = (filterParams: IFilterForm, tab: ETab) => {
    // If filterParams.osdType is defined and non-empty, return it directly
    if (filterParams.osdType && filterParams.osdType.length > 0) {
      return filterParams.osdType;
    }

    // Otherwise, return the appropriate array based on the tab value
    switch (tab) {
      case ETab.myAssignments:
        return [...OverageOsdType, ...ShortageOsdType];
      case ETab.overages:
        return [...OverageOsdType];
      case ETab.shortages:
        return [...ShortageOsdType];
      default:
        // Handle unexpected tab values (optional)
        throw new Error(`Invalid tab value: ${tab}`);
    }
  };

  // Memoize columnVisibilityModel, terminal list and exception type
  const terminalList = useMemo(
    () => getTerminalList(currentTerminalValue, terminals),
    [currentTerminalValue, terminals]
  );
  const exceptionType = useMemo(
    () => getExceptionType(filterParams, tab),
    [filterParams, tab]
  );

  const columnVisibilityModel = useMemo(
    () => ({
      assignedToName: currentTabPosition !== ETab.myAssignments,
      assignedByName: currentTabPosition === ETab.myAssignments
    }),
    [currentTabPosition]
  );

  // Fetching exception data
  const fetchExceptionList = () => {
    const { page = 0, pageSize = 0 } = pageObject ?? {};
    const sortField = sortModel?.field;
    const sortOrder = sortModel?.sort;
    const entryDateFrom = DateHelper.startDateSelector(filterParams.date);
    const entryDateTo = DateHelper.endDateSelector(filterParams.date);
    const od400ProNumber = filterParams.od400ProNumber
      ? +filterParams.od400ProNumber
      : null;
    const osdNumber = filterParams.osdNumber ? +filterParams.osdNumber : null;
    const assignedBy =
      tab === ETab.myAssignments
        ? _.get(filterParams, "assignedBy.as400Id", "").trim()
        : undefined;
    const assignedTo =
      tab === ETab.myAssignments
        ? Username()
        : _.get(filterParams, "assignedTo.as400Id", "").trim();
    //
    const destination =
      tab === ETab.overages || tab === ETab.myAssignments
        ? filterParams.destination!
        : currentTerminalValue?.value === "All"
        ? undefined
        : terminalList;
    const reporting =
      tab === ETab.shortages || tab === ETab.myAssignments
        ? filterParams.reporting!
        : currentTerminalValue?.value === "All"
        ? undefined
        : terminalList;

    const params = {
      page: page + 1,
      limit: pageSize,
      sortField,
      sortOrder,
      search: filterParams?.search,
      origin: filterParams.origin!,
      entryDateFrom,
      entryDateTo,
      commodityType: filterParams.commodityType!,
      description: filterParams?.description,
      proNumber: filterParams.proNumber ? +filterParams.proNumber : null,
      entryBy: _.get(filterParams, "entryUser.as400Id", "").trim(),
      values: filterParams.values!,
      status: filterParams.status!,
      osdType: exceptionType,
      od400ProNumber,
      osdNumber,
      assignedBy,
      assignedTo,
      destination,
      reporting,
    };

    setFilterCriteria(
      FilterCriteriaValue(
        params,
        tab,
        !filterParams.osdType || filterParams?.osdType?.length < 1
      )
    );

    setLoading(true);
    setExceptions([]);

    getExceptionList(params)
      .then((response) => {
        setExceptions(response?.data ?? []);
        setRowCount(response?.total ?? 0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            error,
            Date.now().toString()
          )
        );
      });
  };

  useEffect(() => {
    if (tabPosition === tab) fetchExceptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTerminalValue, pageObject, sortModel, filterParams, tabPosition]);

  useEffect(() => {
    dispatch(FooterTabPosition(tab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch]);

  return (
    <div className="layout overage-layout">
      <div className="filter-criteria">
        Filter Applied: {filterCriteria || "None"}
      </div>
      <DynamicGrid
        id="osdNumber"
        checkboxSelection
        key={`${currentTerminalValue}-${tab}`}
        rows={exceptions}
        tab={tab}
        loading={loading}
        rowCount={rowCount}
        onSuccessFullyAssigned={fetchExceptionList}
        columnVisibilityModel={columnVisibilityModel}
      />
    </div>
  );
}

export default Exceptions;
