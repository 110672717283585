import { createSelector } from "reselect";
import { AppState } from "../appState";
import { TTerminal } from "../overagesFilterFormActions";

// Base selector to access terminal master state
export const terminalSelector = (state: AppState) => state.terminalMaster;


// Memoized selector to get terminal values master
export const terminalValuesSelector = createSelector(
  [terminalSelector],
  (terminals) => terminals.filter((t: TTerminal) => t.region !== null).map((t: TTerminal) => t.value));