import { createSelector } from "reselect";
import { AppState } from "../appState";
import { ETab } from "../../constants/app";
import { tabPositionSelector } from "./tabSelectors";

// Base selectors
const filterExceptionCodeDataSelector = (state: AppState) =>
  state.filterExceptionCodeData;
const allExceptionCodeDataSelector = (state: AppState) =>
  state.allExceptionCodeData;
const shortageExceptionCodeDataSelector = (state: AppState) =>
  state.shortageExceptionCodeData;

// Memoized selector to get exception codes based on tab position
export const exceptionCodesSelector = createSelector(
  [
    tabPositionSelector,
    filterExceptionCodeDataSelector,
    allExceptionCodeDataSelector,
    shortageExceptionCodeDataSelector,
  ],
  (
    tabPosition,
    filterExceptionCodeData,
    allExceptionCodeData,
    shortageExceptionCodeData
  ) => {
    if (tabPosition === ETab.overages) {
      return filterExceptionCodeData;
    } else if (tabPosition === ETab.myAssignments) {
      return allExceptionCodeData;
    } else {
      return shortageExceptionCodeData;
    }
  }
);
