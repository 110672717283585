import { createSelector } from "reselect";
import { AppState } from "../appState";
import { ETab } from "../../constants/app";
import { tabPositionSelector } from "./tabSelectors";
import _ from "lodash";
// Base selectors
const shortageStatusMasterState = (state: AppState) =>
  state.shortageStatusMasterData;
const filterStatusMasterState = (state: AppState) => state.filterStatusData;
const myAssignmentStatusMasterState = (state: AppState) => state.myAssignmentStatusMaster;

// Memoized selector to get investigation status based on tab position
export const investigationStatusSelector = createSelector(
  [tabPositionSelector, shortageStatusMasterState, filterStatusMasterState, myAssignmentStatusMasterState],
  (tabPosition, shortageStatusMaster, filterStatusMaster, myAssignmentStatusMasterState) => {
    switch (tabPosition) {
      case ETab.shortages:
        return shortageStatusMaster;
      case ETab.overages:
        return filterStatusMaster;
      case ETab.myAssignments:
        if(shortageStatusMaster.length > 0 &&  filterStatusMaster?.length>0)
          return _.uniqBy([...shortageStatusMaster, ...filterStatusMaster], "displayValue");
        else return [];
      default:
        return [];
    }
  }
);
