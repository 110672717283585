import {
  AccordionDetails,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DynamicGrid from "../dynamicGrid/DynamicGrid";
import { APP, EUploadKey } from "../../constants/app";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { red } from "@mui/material/colors";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { useDispatch, useSelector } from "react-redux";
import { FileUploadAPI } from "../../API/fileUpload";
import { CompressImage } from "../../helpers/utils.helper";
import { IUploadFile } from "../../models/master";
import { GetDocTypeAPI } from "../../API/getDocType";
import { saveDocTypesState } from "../../AppState/docTypes";
import AppState from "../../AppState";
import _ from "lodash";
import Loader from "../loader/loader";
import { isFormValueChanged } from "../../AppState/isFormValueChanged";

interface UploadDocumentProps {
  osdNumber?: number;
  uploadKey?: EUploadKey;
}

const UploadDocument: React.FC<UploadDocumentProps> = (
  props: UploadDocumentProps
): JSX.Element => {
  const dispatch = useDispatch();
  const docTypes = useSelector((state: AppState) => state.docTypeMaster);

  const [selectedFiles, setSelectedFiles] = useState<IUploadFile[]>([]);
  const [files, setFiles] = useState<File[] | []>([]);
  const [selectedDocType, setSelectedDocType] = useState<string>("");
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files: File[] = event.target.files
      ? Array.from(event.target.files)
      : [];
    const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const allowedPdfType = "application/pdf";

    if (
      files.length === 1 &&
      files[0].type !== allowedPdfType &&
      files[0].size > 200000
    ) {
      files[0] = await CompressImage(files[0], 0.2);
    }

    if (files.length + (selectedFiles?.length || 0) > 5) {
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.INFO,
          "You can only select up to 5 files, including the ones you have already selected",
          Date.now().toString()
        )
      );
      const fileInput = event.target;
       // Create a DataTransfer object to hold valid files
      fileInput.value = '';
      return;
    }

    const validFiles: File[] = [];
    const excludedFiles: string[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === allowedPdfType && file.size <= 4000000) {
        validFiles.push(file);
      } else if (
        allowedImageTypes.includes(file.type) &&
        file.size <= 200000 &&
        file.size >= 1000
      ) {
        validFiles.push(file);
      } else {
        excludedFiles.push(file.name);
      }
    }

    if (excludedFiles.length > 0) {
      const message =
        excludedFiles.length > 1
          ? `Files ${excludedFiles.join(
              ", "
            )} were not uploaded because their image(s) size is not between 1KB to 200KB or PDF file(s) size exceeds 4MB. or their type is not one of ${allowedImageTypes.join(
              ", "
            )}, ${allowedPdfType} `
          : `File ${excludedFiles.join(
              ", "
            )} was not uploaded because its image size is not between 1KB to 200KB or PDF file(s) size exceeds 4MB. or its type is not one of ${allowedImageTypes.join(
              ", "
            )}, ${allowedPdfType}`;
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.INFO,
          message,
          Date.now().toString()
        )
      );
    }

    // Create a new FileList object that only includes the valid files
    const dataTransfer = new DataTransfer();
    validFiles.forEach((file) => dataTransfer.items.add(file));
    event.target.files = dataTransfer.files;

    setFiles(validFiles);
  };

  const heading: { [key: string]: string } = APP.documentColumns;
  const rowCols: string[] = Object.keys(heading);
  // Configure columns and add manually style and
  const columns: GridColDef[] = rowCols.map((ele: string) => {
    //customized overages grid columns like width, add custom links etc.
    let row: GridColDef = {
      field: ele,
      headerName: heading[ele],
      description: heading[ele],
      type: "string",
      editable: false,
      sortable: false,
      flex: 1,
      headerAlign: "left",
      align: "left",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    };
    switch (heading[ele]) {
      case "Doc Type":
      case "Delete":
        row["minWidth"] = 30;
        break;
      case "File Name":
        row["minWidth"] = 120;
        break;
    }

    if (ele === "id") {
      row["renderCell"] = (params: GridRenderCellParams) => (
        <div>
          <DeleteOutlineIcon
            style={{ color: red[500], cursor: "pointer" }}
            className="blue-color"
            onClick={() => {
              onDelete(params.row["id"]);
            }}
            to={""}
          />
        </div>
      );
    }
    return row;
  });

  const onDelete = (id: string) => {
    const newSelectedFiles = selectedFiles.filter(
      (file: IUploadFile) => file.id !== id
    );
    setSelectedFiles(newSelectedFiles);
  };

  const handleDocTypeChange = (docType: string) => {
    setSelectedDocType(docType);
  };

  const AddImages = () => {
    const newRows = files.map((file: File, index: number) => ({
      id:  `${Date.now()}-${index}`,
      name: file.name,
      docType: selectedDocType,
      file: file,
    }));
    setSelectedFiles([...selectedFiles, ...newRows]);
    setFiles([]);
    setSelectedDocType("");
  };

  const selectStyle = {
    fontSize: "13px",
  };

  const upload = async () => {
    const uploadPromises = selectedFiles.map((fileObj: IUploadFile) => {
      const reqInput: IUploadFile = {
        osdNumber: props?.osdNumber,
        docType: fileObj.docType,
        uploadKey: props.uploadKey || EUploadKey.od400Pro,
      };
      return FileUploadAPI([fileObj.file!], reqInput);
    });

    try {
      setPageLoading(true);
      const uploadDocResult = await Promise.all(uploadPromises);
       
      // Check for errors in the response
      const allErrors = uploadDocResult.flatMap(res => _.get(res, "errors", []));
      const hasErrors = allErrors.length > 0;

      const notificationType = hasErrors ? NOTIFICATION_TYPE.ERROR : NOTIFICATION_TYPE.SUCCESS;
      const message = hasErrors
        ? allErrors.join(", ")
        : uploadDocResult[0].result || "Document(s) successfully uploaded";

      // Clear the files and update state
      setSelectedFiles([]);
      setFiles([]);
      dispatch(isFormValueChanged(false));

      // Dispatch notification
      dispatch(PopoutNotification(notificationType, message, Date.now().toString()));
      
    } catch (error) {
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          typeof error === "string" ? error : `Failed Document(s) uploading`,
          Date.now().toString()
        )
      );
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    const getDocType = async () => {
      try {
        const response = await GetDocTypeAPI();
        dispatch(saveDocTypesState(_.get(response, "data", [])));
      } catch (error) {
        console.log(error);
      }
    };
    if (
      !Array.isArray(docTypes) ||
      (Array.isArray(docTypes) && docTypes.length < 1)
    ) {
      getDocType();
    }
    dispatch(isFormValueChanged(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      if (selectedDocType && selectedFiles?.length < 6) {
        AddImages();
      }
      dispatch(isFormValueChanged(true));
    } else if (selectedDocType || selectedFiles.length > 0) {
      dispatch(isFormValueChanged(true));
    } else {
      dispatch(isFormValueChanged(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, selectedDocType, selectedFiles]);

  return (
    <>
      <AccordionDetails>
        <Grid container spacing={2} className="filter-margin">
          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="file">
                Upload Documents or Capture Image (Max 5 files)<sup>&nbsp;</sup>
              </InputLabel>
              <TextField
                id="docType"
                placeholder="Select Files"
                variant="outlined"
                inputProps={{
                  multiple: true,
                  accept: ".jpg,.jpeg,.png,.pdf",
                }}
                name=" "
                type="file"
                disabled={selectedFiles?.length >= 5}
                key={selectedFiles.length}
                onChange={handleFileChange}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="type">
                Doc Type <sup>&nbsp;</sup>
              </InputLabel>
              <Select
                variant="outlined"
                disabled={selectedFiles?.length >= 5}
                id="fileType"
                className="select"
                fullWidth
                onChange={(e) => handleDocTypeChange(e.target.value)}
                name="fileType"
                value={selectedDocType}
                style={selectStyle}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select Doc Type</em>;
                  }
                  return selected;
                }}
                IconComponent={() => (
                  <div className="dropdown-icon-group">
                    <ArrowDropUpIcon className="icon-style" />{" "}
                    <ArrowDropDownIcon className="icon-style" />{" "}
                  </div>
                )}
              >
                {docTypes.map((docType: string) => (
                  <MenuItem style={selectStyle} key={docType} value={docType}>
                    {docType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="filter-margin">
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}></Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>
        {selectedFiles && selectedFiles.length > 0 && (
          <Grid container spacing={2} className="filter-margin">
            <Grid item md={6} xs={12}>
              <DynamicGrid
                id="id"
                key={selectedFiles.toString()}
                checkboxSelection={false}
                rows={selectedFiles}
                columns={columns}
                hideFooter={true}
                disableColumnMenu={true}
                paginationMode={"client"}
              ></DynamicGrid>
            </Grid>
          </Grid>
        )}

        <div className="btn-group-submit">
          <Button
            variant="contained"
            type="button"
            className={
              selectedFiles.length === 0
                ? "disable-btn disable assign mr-0 ml"
                : "assign mr-0 ml"
            }
            onClick={() => {
              selectedFiles.length === 0 ? void 0 : upload();
            }}
          >
            Upload
          </Button>
        </div>
      </AccordionDetails>

      <Loader pageLoader={pageLoading}></Loader>
    </>
  );
};

export default UploadDocument;
