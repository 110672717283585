import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
type Props = {
  pageLoader?: boolean;
  showProgress?: boolean;
};

const Loader: React.FC<Props> = ({
  pageLoader = false,
  showProgress = true,
}: Props): JSX.Element => {
    return (
    <div>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={pageLoader!}
        >
         {showProgress && <CircularProgress color="inherit" /> }
        </Backdrop>
    </div>
  );
};

export default Loader
