// API Types for getURL
export enum ApiType {
  TERMINAL = "TERMINAL",
  VALIDATE_OKTA_TOKEN = "VALIDATE_OKTA_TOKEN",
  EXCEPTIONS_LIST = "EXCEPTIONS_LIST",
  OVERAGE_FILTER_MASTER_USER = "OVERAGE_FILTER_MASTER_USER",
  EXCEPTION_ASSIGN = "EXCEPTION_ASSIGN",
  EXCEPTION_DETAILS = "EXCEPTION_DETAILS",
  PRO_IMAGES = "PRO_IMAGES",
  DASHBOARD_KRA = "DASHBOARD_KRA",
  MASTER_RECORDS = "MASTER_RECORDS",
  MANIFEST_DETAILS = "MANIFEST_DETAILS",
  MANIFEST_PRO_DETAILS = "MANIFEST_PRO_DETAILS",
  SEND_APB_MAIL = "SEND_APB_MAIL",
  MASTER_PRO_NUMBER = "MASTER_PRO_NUMBER",
  MASTER_OSD_NUMBER = "MASTER_OSD_NUMBER",
  COMMODITY_TYPE_MASTER = "COMMODITY_TYPE_MASTER",
  LOCATION_MASTER = "LOCATION_MASTER",
  EXCEPTION_CODE_MASTER = "EXCEPTION_CODE_MASTER",
  CHECK_PRO = "CHECK_PRO",
  DEPLOYMENT_STATUS = "DEPLOYMENT_STATUS",
  OVERAGE_CREATE = "OVERAGE_CREATE",
  SHORTAGE_ENTRY_CREATE = "SHORTAGE_ENTRY_CREATE",
  OVERAGE_UPDATE = "OVERAGE_UPDATE",
  AS_ENTRY_UPDATE = "AS_ENTRY_UPDATE",
  DS_ENTRY_UPDATE = "DS_ENTRY_UPDATE",
  OVERAGE_ENTRY_DETAILS = "OVERAGE_ENTRY_DETAILS",
  AS_ENTRY_DETAILS = "AS_ENTRY_DETAILS",
  DS_ENTRY_DETAILS = "DS_ENTRY_DETAILS",
  SHORTAGE_INVESTIGATION = "SHORTAGE_INVESTIGATION",
  SAVE_OR_SUBMIT_INVESTIGATION = "SAVE_OR_SUBMIT_INVESTIGATION",
  UPLOAD_FILE = "UPLOAD_FILE",
  DOC_TYPE = "DOC_TYPE",
  SAVE_OR_SUBMIT_DS_INVESTIGATION = "SAVE_OR_SUBMIT_DS_INVESTIGATION",
  BNF_EDIT_VIEW = "BNF_EDIT_VIEW",
  BNF_FOLLOWUP = "BNF_FOLLOWUP",
  PRINT="PRINT",
  LOGGLY_LOG="LOGGLY_LOG"
}

// Error messages
export const cancelErrorMessage = "Request Cancelled";

export type ApiErrorMessage = {
  errors: any;
  message: any;
};

