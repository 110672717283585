import { createSelector } from "reselect";
import { AppState } from "../appState";
import { IFilterDropdown } from "../overagesFilterFormActions";

// Base selector to access commodity state
const commoditySelector = (state: AppState) => state.filterCommodityTypeData;

// Memoized selector to get commodity details

// Memoized selector to transform the data
export const commodityDisplayValueSelector = createSelector(
  [commoditySelector],
  (filterCommodityTypeData): string[] =>
    filterCommodityTypeData.map((item: IFilterDropdown) => item.displayValue)
);
