import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IRequestParametersOsd, IExceptionList } from "../models/osd";

/**
 * GET API call using Axios
 * @returns - Array of Exception List of type IExceptionList[]
 */

export const getExceptionList = async (params: IRequestParametersOsd): Promise<IExceptionList> => {
  try {
    const response = await axios.post(getUrl(ApiType.EXCEPTIONS_LIST), params, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.getExceptionList;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message =  _.get(error, "response.data.errors[0].message");
    }
    throw message;
  }
};
