import React, { useEffect } from 'react'; 
import './maintenance.css';
import { useDispatch } from 'react-redux';
import { FooterTabPosition } from '../../AppState/footerTabPosition';
import { ETab } from '../../constants/app';
import { useNavigate } from 'react-router-dom';
import { deploymentStatusApi, } from '../../API/deploymentStatus';

const MaintenancePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(FooterTabPosition(ETab.maintenance));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  useEffect(() => {
    const deploymentStatus = async () => {
      try {
        const response = await deploymentStatusApi();
        if (response) {
          navigate(`/`);
        }
      } catch (error) {}
    };

    deploymentStatus();
  }, [navigate]); // Include navigate in the dependency array
    return (
        <div className='image-wrapper'> 
            <img src="/maintenance.jpg" alt="Maintenance" style={{ display: 'block', margin: '0 auto' }} />
        </div>
    );
};

export default MaintenancePage;