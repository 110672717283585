import { IDSInvestigation } from "../models/dsException";
import { CreateDynamicSchema, FieldConfig } from "./formik";

//  AS Investigation form Start
const dsInvestigationFields: FieldConfig[] = [
  // Day 1 Section (6 keys)
  {
    name: "statusCorrect",
    type: "string",
  },
  {
    name: "statusResponse",
    type: "string",
    nullable: true,
  },
  {
    name: "deliveryCode",
    type: "string",
  },
  {
    name: "deliveryCodeResponse",
    type: "string",
    nullable: true,
  },
  {
    name: "epImaging",
    type: "string",
  },
  {
    name: "epImagingResponse",
    type: "string",
    nullable: true,
  },

  // Day 2 Section (7 keys)
  {
    name: "noMovement",
    type: "string",
  },
  {
    name: "dateLastMovement",
    type: "string",
    nullable: true,
  },
  {
    name: "noMovementSteps",
    type: "string",
    nullable: true,
  },
  {
    name: "suffixBill",
    type: "string",
  },
  {
    name: "suffixBillSteps",
    type: "string",
    nullable: true,
  },
  {
    name: "spotTrailer",
    type: "string",
    nullable: true,
  },
  {
    name: "freightLeft",
    type: "string",
    nullable: true,
  },

  // Day 3 Section (32 keys)
  {
    name: "bolCheck",
    type: "string",
  },
  {
    name: "bolSteps",
    type: "string",
    nullable: true,
  },

  {
    name: "bolDiscrepancies",
    type: "string",
    nullable: true,
  },
  {
    name: "drCheck",
    type: "string",
  },
  {
    name: "confirm",
    type: "string",
  },
  {
    name: "confirmSteps",
    type: "string",
    nullable: true,
  },
  {
    name: "pumCheck",
    type: "string",
  },
  {
    name: "pumManifest",
    type: "number",
    nullable: true,
  },
  {
    name: "pumDiscrepancy",
    type: "string",
    nullable: true,
  },
  {
    name: "shipperLoad",
    type: "string",
  },
  {
    name: "shipperDiscrepancy",
    type: "string",
    nullable: true,
  },
  {
    name: "lineHaulManifest",
    type: "string",
    nullable: true,
  },
  {
    name: "numbersReview",
    type: "string",
    nullable: true,
  },
  {
    name: "interviewWorkers",
    type: "string",
  },
  {
    name: "workerSteps",
    type: "string",
    nullable: true,
  },
  {
    name: "consigneePhone",
    type: "string",
  },
  {
    name: "consigneeName",
    type: "string",
  },
  {
    name: "consigneeShort",
    type: "string",
    nullable: true,
  },
  {
    name: "itemNumbers",
    type: "string",
    nullable: true,
  },
  {
    name: "orderReceived",
    type: "string",
    nullable: true,
  },
  {
    name: "freightLabeled",
    type: "string",
    nullable: true,
  },
  {
    name: "shipperPhone",
    type: "string",
    required: false,
    message: "Shipper Phone Number is required",
  },
  {
    name: "shipperName",
    type: "string",
    required: false,
    message: "Shipper Name is required",
  },
  {
    name: "shippedDetails",
    type: "string",
  },
  {
    name: "shortShipped",
    type: "string",
  },
  {
    name: "productValue",
    type: "string",
  },
  {
    name: "multiShipments",
    type: "string",
  },
  {
    name: "callDriver",
    type: "string",
  },
  {
    name: "adeDelivery",
    type: "string",
    nullable: true,
  },
  {
    name: "freightNose",
    type: "string",
    nullable: true,
  },
  {
    name: "outOfOrdinary",
    type: "string",
    nullable: true,
  },

  // Day 4 Section (3 keys)
  {
    name: "check7818",
    type: "string",
  },
  {
    name: "details7818",
    type: "string",
    nullable: true,
  },
  {
    name: "cameraFindings",
    type: "string",
    nullable: true,
  },

  // Day 5 Section (21 keys)
  {
    name: "callSameDays",
    type: "string",
  },
  {
    name: "sameDayPros",
    type: "string",
    nullable: true,
    when: {
      key: "callSameDays",
      value: "Y",
      required: false,
      message: "Answer is required",
    },
  },
  {
    name: "contactTerminals",
    type: "string",
  },
  {
    name: "investigationDetails",
    type: "array",
    schema: [
      {
        name: "terminal",
        type: "string",
        nullable: true,
        required: true,
        message: "Field is required",
      },
      {
        name: "talkedTo",
        type: "string",
        nullable: true,
        required: true,
        message: "Field is required",
      },
      {
        name: "updates",
        type: "string",
        nullable: true,
        required: true,
        message: "Field is required",
      },
    ],
  },

  {
    name: "followUp",
    type: "string",
  },

  {
    name: "followUpDetails",
    type: "number",
    nullable: true,
  },

  {
    name: "check7818_2",
    type: "string",
  },

  {
    name: "details7818_2",
    type: "number",
    nullable: true,
  },
  {
    name: "highValue",
    type: "string",
  },

  {
    name: "finalReCheck",
    type: "string",
  },
];

// Exporting the dynamically created schema
export const DSInvestigationSchema = CreateDynamicSchema(dsInvestigationFields);

export const DSInvestigationInitValue: IDSInvestigation = {
  // Day 1 Section (6 keys)
  statusCorrect: undefined,
  statusResponse: undefined,
  deliveryCode: undefined,
  deliveryCodeResponse: undefined,
  epImaging: undefined,
  epImagingResponse: undefined,

  // Day 2 Section (7 keys)
  noMovement: undefined,
  dateLastMovement: undefined,
  noMovementSteps: undefined,
  suffixBill: undefined,
  suffixBillSteps: undefined,
  spotTrailer: undefined,
  freightLeft: undefined,

  // Day 3 Section (32 keys)
  bolCheck: undefined,
  bolSteps: undefined,
  bolDiscrepancies: undefined,
  drCheck: undefined,
  confirm: undefined,
  confirmSteps: undefined,
  pumCheck: undefined,
  pumManifest: undefined,
  pumDiscrepancy: undefined,
  shipperLoad: undefined,
  shipperDiscrepancy: undefined,
  lineHaulManifest: undefined,
  numbersReview: undefined,
  interviewWorkers: undefined,
  workerSteps: undefined,
  consigneePhone: undefined,
  consigneeName: undefined,
  consigneeShort: undefined,
  itemNumbers: undefined,
  orderReceived: undefined,
  freightLabeled: undefined,
  shipperPhone: undefined,
  shipperName: undefined,
  shippedDetails: undefined,
  shortShipped: undefined,
  productValue: undefined,
  multiShipments: undefined,
  callDriver: undefined,
  adeDelivery: undefined,
  freightNose: undefined,
  outOfOrdinary: undefined,

  // Day 4 Section (3 keys)
  check7818: undefined,
  details7818: undefined,
  cameraFindings: undefined,

  // Day 5 Section (21 keys)
  callSameDays: undefined,
  sameDayPros: undefined,
  contactTerminals: undefined,
  investigationDetails: [
    {
      terminal: undefined,
      talkedTo: undefined,
      updates: undefined,
    },
  ],

  // Day 10 Section (2 keys)
  followUp: undefined,
  followUpDetails: undefined,

  // Day 15 Section (3 keys)
  check7818_2: undefined,
  details7818_2: undefined,
  highValue: undefined,

  // Day 20 Section (2 keys)
  finalReCheck: undefined,
  detailsOfSearch: undefined,
};
// AS Investigation form End
