import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import { AppState } from "../../AppState/appState";
import { deploymentStatusApi } from "../../API/deploymentStatus";

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const isLoggedIn: boolean = useSelector(
    (state: AppState) => state.isLoggedIn
  );

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {

      const deploymentStatus = async () => {
        try {
          const response = await deploymentStatusApi();
          if (response) {
            try {
              localStorage.clear();
              const originalUri = toRelativeUrl(
                window.location.href,
                window.location.origin
              );
              oktaAuth.setOriginalUri(originalUri);
              oktaAuth.signInWithRedirect();
            } catch (error) {
              console.error("Error during authentication flow:", error);
            }
          }
        } catch (error) {}
      };
  
      deploymentStatus();
     
    }
  }, [oktaAuth, authState, isLoggedIn]);

  if (!authState?.isAuthenticated || !isLoggedIn) {
    return <Loading />;
  }

  return <Outlet />;
};
