import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { TTerminal } from "../AppState/overagesFilterFormActions";

const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 1000; // Delay between retries in milliseconds

/**
 * GET API call using Axios with retry logic
 * @returns - Array of Terminal List of type TTerminal[]
 */
export const getTerminals = async (): Promise<TTerminal[]> => {
  let attempts = 0;

  while (attempts < MAX_RETRIES) {
    try {
      const response = await axios.get(getUrl(ApiType.TERMINAL), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return _.get(response, "data.data", []);
    } catch (error) {
      attempts++;
      let message = ConstantMessage.terminalAPIError;
      const status = _.get(error, "response.status") ;
      if(!status) throw ConstantMessage.statusCodeNotFound
      if(status === 503) {
        message = _.get(error, "response.data.errors[0].message", message);
        throw ConstantMessage.deploymentInprogressError;
      }
      if (status === 500) {
        message = ConstantMessage.serverError;
      }

      if (attempts < MAX_RETRIES) {
        console.warn(`Attempt ${attempts} failed. Retrying in ${RETRY_DELAY_MS}ms...`);
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY_MS));
      } else {
        console.error("Maximum retry attempts reached.");
        throw message;
      }
    }
  }

  throw new Error(ConstantMessage.terminalAPIError); // This line should never be reached due to the throw in the catch block
};
