import React, { useState } from "react";
import { Link, LinkProps, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { default as Tab, TabProps } from "@mui/material/Tab";
import { AppState } from "../../AppState/appState";
import { useDispatch, useSelector } from "react-redux";

import { FooterTabPosition } from "../../AppState/footerTabPosition";
import CustomDialog from "../confirmationPopup/confirmationPopup";
import { AppURLs, ETab } from "../../constants/app";
import { hasOveragesPermissionSelector, hasShortagesPermissionSelector } from "../../AppState/selectors/userPermissionsSelectors";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const currentTabPosition = useSelector(
    (state: AppState) => state.tabPosition
  );

  const isFormValueChanged = useSelector(
    (state: AppState) => state.isFormValueChanged
  );

  const hasOveragesPermission = useSelector(hasOveragesPermissionSelector);
  const hasShortagesPermission = useSelector(hasShortagesPermissionSelector);

  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog open/close
  const [navigationPath, setNavigationPath] = useState(""); // State to manage dialog open/close
  const [previousTab, setPreviousTab] = useState(currentTabPosition); // State to manage dialog open/close

  const LinkTab: React.ComponentType<TabProps & LinkProps> =
    Tab as React.ComponentType<TabProps & LinkProps>;

  const tabOnChange = (_event: React.SyntheticEvent, value: number) => {
    setPreviousTab(currentTabPosition);
    dispatch(FooterTabPosition(value));
  };

  const onTabClick = (e: any, navigateTo: string) => {
    e.preventDefault();
    setNavigationPath(navigateTo)
    if(location.pathname.includes("/entry") && isFormValueChanged) {
      setDialogOpen(true);
    } else {
      navigate(navigateTo);
    }
     
  }
    // Close the confirmation modal pop and navigate user to back overage listing page if user confirmed
    const handleDialogClose = (flag?: string) => {
      setDialogOpen(false);
      if (flag === "yes") {
        navigate(navigationPath);
      } else {
        dispatch(FooterTabPosition(previousTab));
      }
    };

  return (
    <div>
       {![ETab.maintenance, ETab.unauthorized].includes(currentTabPosition) ? ( <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabPosition}
          onChange={tabOnChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="nav tabs example"
        >
          <LinkTab onClick={(e) => {onTabClick(e, AppURLs.dashboard)}} label="Dashboard" LinkComponent={Link} to={AppURLs.dashboard} />
          <LinkTab className={hasOveragesPermission ? "" : "hide-me-tab"} onClick={(e) => {onTabClick(e, AppURLs.overages)}} label="Overages" LinkComponent={Link} to={AppURLs.overages} />
          <LinkTab onClick={(e) => {onTabClick(e, AppURLs.myAssignments)}} label="My Assignment" LinkComponent={Link} to={AppURLs.myAssignments} />
          <LinkTab className={hasShortagesPermission ? "" : "hide-me-tab"} onClick={(e) => {onTabClick(e, AppURLs.shortages)}} label="Shortages" LinkComponent={Link} to={AppURLs.shortages} />
        </Tabs>
      </Box>
      <CustomDialog open={dialogOpen} onClose={handleDialogClose} />
      </>) : (null) 
      }
    </div>
  );
};

export default Footer;
