import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IASEntry } from "../models/shortage";

/**
 * GET API call using Axios
 */

export const GetASDetailsAPI = async (osdNumber: number | string): Promise<IASEntry> => {
  try {
    const response = await axios.get(`${getUrl(ApiType.AS_ENTRY_DETAILS)}/${osdNumber}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return _.get(response, "data", {});
  } catch (error) {
    let message = ConstantMessage.aSEntryDetailsAPIError;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message =_.get(error, "response.data.errors[0].message");
    }
    throw message;
  }
};
