import _ from "lodash";
import React, { useMemo } from "react";
import { IDSInvestigation, IDSDay5Investigation } from "../models/dsException";
import { ASInvestigationWizardStep } from "../constants/shortage";

interface DSInvestigationTemplateProps {
  investigationValues?: IDSInvestigation;
  osdNumber: number;
  currentDay?: string;
}

const DSInvestigationTemplate: React.FC<DSInvestigationTemplateProps> = ({
  investigationValues,
  osdNumber,
  currentDay,
}: DSInvestigationTemplateProps): JSX.Element => {

  const values = useMemo(() => investigationValues, [investigationValues]);
  return (
    <>
      {values && (
        <>
          {currentDay === ASInvestigationWizardStep[0] && (
            <div id="ds-in-day">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 1 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>

              {/* Confirm Delivery Status */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Confirm the delivery status is correct
                </div>
                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.statusCorrect === "Y" ? "Yes" : "No"}
                  </div>
                  {values.statusCorrect === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.statusResponse}
                    </div>
                  )}
                </div>
              </div>

              {/* Confirm Delivery Code */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Confirm the delivery code matches the ADE and is populated
                  correctly in the F7
                </div>
                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.deliveryCode === "Y" ? "Yes" : "No"}
                  </div>
                  {values.deliveryCode === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.deliveryCodeResponse}
                    </div>
                  )}
                </div>
              </div>

              {/* EP Imaging */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Are there EP’s in imaging?
                </div>
                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.epImaging === "Y" ? "Yes" : "No"}
                  </div>
                  {["Y", "N"].includes(values.epImaging || "") && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.epImagingResponse}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {currentDay === ASInvestigationWizardStep[1] && (
            <div id="ds-in-day2">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 2 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Confirm the master shows no movement?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.noMovement === "Y" ? "Yes" : "No"}
                  </div>
                  <br />
                  {values.noMovement === "Y" && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>
                        Select date of last movement
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.dateLastMovement}
                      </div>{" "}
                      <br />
                    </>
                  )}
                  {values.noMovement === "Y" && values.noMovementSteps && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>Described steps</div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.noMovementSteps}
                      </div>{" "}
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check if a suffix bill or OD400 was cut to move some or all of
                  the freight.
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.suffixBill === "Y" ? "Yes" : "No"}
                  </div>
                  {values.suffixBill === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.suffixBillSteps}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Did the driver spot the trailer somewhere?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.spotTrailer === "Y" ? "Yes" : "No"}
                  </div>
                  <br />
                  {values.spotTrailer === "Y" && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>
                        Was freight left on board?
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.freightLeft}
                      </div>{" "}
                      <br />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {currentDay === ASInvestigationWizardStep[2] && (
            <div id="ds-in-day3">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 3 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check the BOL to confirm completion and check for
                  discrepancies
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.bolCheck === "Y" ? "Yes" : "No"}
                  </div>
                  <br />
                  {values.bolCheck === "Y" && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>
                        Use info on BOL to look for duplicate pros/billing error
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.bolSteps}
                      </div>{" "}
                      <br />
                    </>
                  )}
                  {values.bolCheck === "Y" && values.bolDiscrepancies && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>
                        List any and all discrepancies
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.bolDiscrepancies}
                      </div>{" "}
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>Is there a DR?</div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.drCheck === "Y" ? "Yes" : "No"}
                  </div>
                  {values.drCheck === "Y" && (
                    <>
                      <div>Confirm the master shows no movement?:</div> <br />
                      <div
                        style={{
                          width: "15vw",
                          display: "inline-block",
                          verticalAlign: "top",
                        }}
                      >
                        {values.confirm === "Y" ? "Yes" : "No"}
                      </div>
                    </>
                  )}
                  {values.confirm === "Y" && (
                    <>
                      <div>"Describe Steps</div> <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.confirmSteps}
                      </div>
                      <br />
                      <br />
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check the PUM (Pickup manifest) for discrepancies, shipper
                  load and count information, and proper reporting process
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.pumCheck === "Y" ? "Yes" : "No"}
                  </div>
                  {values.pumCheck === "Y" && (
                    <>
                      <div>Pickup Manifest #</div> <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.pumManifest}
                      </div>
                      <br />
                      <br />
                    </>
                  )}

                  {values.pumCheck === "Y" && (
                    <>
                      <div>List any and all discrepancies: </div> <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.pumDiscrepancy}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Was this a shipper load and count?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.shipperLoad === "Y" ? "Yes" : "No"}
                  </div>
                  <br />
                  <br />
                  {values.shipperLoad === "Y" && (
                    <>
                      <div>
                        Were there any discrepancies? If so, were they properly
                        reported to the shipper?
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.shipperDiscrepancy}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check all linehaul manifests – movement and abnormalities.
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.lineHaulManifest === "Y" ? "Yes" : "No"}
                  </div>
                  {values.lineHaulManifest === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.numbersReview}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Interview the dock worker(s) about movement abnormalities.
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.interviewWorkers === "Y" ? "Yes" : "No"}
                  </div>
                  {values.interviewWorkers === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.workerSteps}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  <strong>
                    Call consignee to confirm what was ordered and received (if
                    anything)
                  </strong>
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  ></div>
                  <div>Phone Number: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.consigneePhone}
                  </div>
                  <br />
                  <br />
                  <div>Consignee Name: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.consigneeName}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Is the consignee still short?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.consigneeShort === "Y" ? "Yes" : "No"}
                  </div>
                  {values.consigneeShort === "Y" && (
                    <>
                      <div>
                        {" "}
                        What are the item numbers received and still missing?{" "}
                      </div>{" "}
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.itemNumbers}
                      </div>
                      <br />
                      <br />
                    </>
                  )}

                  {values.consigneeShort === "Y" && (
                    <>
                      <div> What was ordered and received for each PO? </div>{" "}
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.orderReceived}
                      </div>
                      <br />
                      <br />
                    </>
                  )}

                  {values.consigneeShort === "Y" && (
                    <>
                      <div> Was the freight received labeled for them? </div>{" "}
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                        }}
                      >
                        {values.freightLabeled}
                      </div>
                      <br />
                      <br />
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  <strong>
                    {" "}
                    Call Shipper to confirm what was shipped, how it was
                    packaged and the value?
                  </strong>
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  ></div>
                  <div> Phone Number: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.shipperPhone}
                  </div>
                  <br />
                  <br />
                  <div> Shipper Name: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.shipperName}
                  </div>
                  <br />
                  <br />
                  <div> What are the details of what shipped? </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.shippedDetails}
                  </div>
                  <br />
                  <br /> <div> Was this short shipped? </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.shortShipped}
                  </div>
                  <br />
                  <br /> <div> What is the value of the product? </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.productValue}
                  </div>
                  <br />
                  <br />{" "}
                  <div>
                    {" "}
                    Were there multiple shipments for the same consignee that
                    day?{" "}
                  </div>{" "}
                  <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.multiShipments}
                  </div>
                  <br />
                  <br />
                  <div>
                    {" "}
                    <strong>Call driver about delivery</strong>{" "}
                  </div>{" "}
                  <br />
                  <div> Who did you talk to? </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.callDriver}
                  </div>
                  <br />
                  <br />
                  <div>What was delivered? Does it match the ADE?</div>
                  <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.adeDelivery}
                  </div>
                  <br />
                  <br />
                  <div> Could the freight be in the nose? </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.freightNose}
                  </div>
                  <br />
                  <br />
                  <div>
                    {" "}
                    Was there anything out of the ordinary about this delivery?{" "}
                  </div>{" "}
                  <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.outOfOrdinary}
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          )}
          {currentDay === ASInvestigationWizardStep[3] && (
            <div id="ds-in-day4">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 4 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check 78/18 and/or Power BI for any overages (OV, NB, DO) to
                  apply
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.check7818 === "Y" ? "Yes" : "No"}
                  </div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.check7818 === "Y" && values.details7818}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div> Check cameras at DT and report findings </div> <br />
                <div
                  style={{
                    width: "75%",
                    margin: "0 0 0 0",
                    display: "inline-block",
                    background: "#f5f7fb",
                    padding: "10px",
                    border: "1px solid #b5b6b9",
                  }}
                >
                  {values.cameraFindings}
                </div>
              </div>
              <br />
            </div>
          )}
          {currentDay === ASInvestigationWizardStep[4] && (
            <div id="ds-in-day5">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 5 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>Call same days</div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.callSameDays === "Y" ? "Yes" : "No"}
                  </div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                    }}
                  >
                    {values.callSameDays === "Y" && values.sameDayPros}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Contact the terminals that handled the freight to find leads
                  and check cameras if applicable
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.contactTerminals === "Y" ? "Yes" : "No"}
                  </div>

                  {values.contactTerminals === "Y" && (
                    <>
                      {_.get(values, "investigationDetails", []).map(
                        (
                          investigationD: IDSDay5Investigation,
                          index: number
                        ) => (
                          <React.Fragment key={index}>
                            <div style={{ marginBottom: "10px" }}>
                              <h3 className="header-text mb-0">
                              <strong> Investigation details: {index + 1} </strong>
                              </h3>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                              TID:{" "}
                            </div>
                            <div
                              style={{
                                width: "75%",
                                margin: "0 0 0 0",
                                display: "inline-block",
                                background: "#f5f7fb",
                                padding: "10px",
                                border: "1px solid #b5b6b9",
                              }}
                            >
                              {_.get(
                                values,
                                `investigationDetails[${index}].terminal`
                              )}
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              Who did you talk to?:{" "}
                            </div>
                            <div
                              style={{
                                width: "75%",
                                margin: "0 0 0 0",
                                display: "inline-block",
                                background: "#f5f7fb",
                                padding: "10px",
                                border: "1px solid #b5b6b9",
                              }}
                            >
                              {_.get(
                                values,
                                `investigationDetails[${index}].talkedTo`
                              )}
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              
                                Provide updates and/or information from the
                                terminal:
                              {" "}
                            </div>
                            <div
                              style={{
                                width: "75%",
                                margin: "0 0 0 0",
                                display: "inline-block",
                                background: "#f5f7fb",
                                padding: "10px",
                                border: "1px solid #b5b6b9",
                              }}
                            >
                              {_.get(
                                values,
                                `investigationDetails[${index}].updates`
                              )}
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {currentDay === ASInvestigationWizardStep[5] && (
            <div id="ds-in-day10">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 10 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Follow up on all leads/request from previous days
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.followUp === "Y" ? "Yes" : "No"}
                  </div>
                  {values.followUp === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.followUpDetails}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {currentDay === ASInvestigationWizardStep[6] && (
            <div id="ds-in-day15">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 15 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check 78/18 and/or Power BI for any overages (OV, NB, DO) to
                  apply
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.check7818_2 === "Y" ? "Yes" : "No"}
                  </div>
                  {values.check7818_2 === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.details7818_2}
                    </div>
                  )}
                </div>

                <div style={{ margin: "20px 0 10px" }}>
                  Is this high value or high quantity?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.highValue === "Y" ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentDay === ASInvestigationWizardStep[7] && (
            <div id="ds-in-day20">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 20 &nbsp; &nbsp;
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Final re-check of all leads/requests/overages to confirm the
                  freight cannot be located at this time
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.finalReCheck === "Y" ? "Yes" : "No"}
                  </div>
                  {values.finalReCheck === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                      }}
                    >
                      {values.detailsOfSearch}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DSInvestigationTemplate;
