import { createSelector } from "reselect";
import { AppState } from "../appState";

// Base selector to access user Permission state
export const userPermissionsSelector = (state: AppState) =>
  state.userPermissions;

// Memoized selector to has Overage permission
export const hasOveragesPermissionSelector = createSelector(
  [userPermissionsSelector],
  (permissions) => permissions?.overages !== false
);

export const hasShortagesPermissionSelector = createSelector(
  [userPermissionsSelector],
  (permissions) => permissions?.shortages !== false
);
