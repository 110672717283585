import "./filterForm.css";
import React, { useEffect, useCallback, useMemo } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { getFilterDropdowns } from "../../API/getFilterDropdowns";
import { getFilterMasterUser } from "../../API/getFilterMasterUser";
import {
  saveFilterExceptionCodeData,
  saveAllExceptionCodeData,
  saveFilterCommodityTypeData,
  saveFilterDateData,
  saveFilterStatusData,
  saveFilterValueData,
  saveOveragesFilterForm,
  IFilterForm,
  TTerminal,
  IFilterDropdown,
  saveShortageExceptionCodeData,
  saveShortageStatusMaster,
  saveMyAssignmentStatusMaster,
} from "../../AppState/overagesFilterFormActions";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { APP, DType, EAutoSearchFieldName, ETab, OverageOsdType, ShortageOsdType, TMasterSearchParameter } from "../../constants/app";
import { getMasterProNumberAPI } from "../../API/getMasterProNumber";
import { getMasterOsdNumberAPI } from "../../API/getMasterOsdNumber";
import { commodityTypeMasterApi } from "../../API/getCommodityTypeMaster";
import { exceptionCodesMasterApi } from "../../API/getExceptionCodesMaster";
import _, { debounce } from "lodash";
import { saveMyAssignmentsFilterForm } from "../../AppState/myAssignmentsFilterFormActions";
import { saveMyAssignmentPageObject, saveOverageListPageObject, saveShortagesPageObject } from "../../AppState/DataTableAction";
import { resetState } from "../../AppState/resetStateActions";
import { getTerminals } from "../../API/getTerminals";
import { saveTerminalMasterState } from "../../AppState/terminalMaster";
import { GridPaginationModel } from "@mui/x-data-grid";
import { NoOptionsText, SortTerminals, UserTerminal } from "../../helpers/utils.helper";
import { TerminalSelection } from "../../AppState/terminalSelection";
import { saveShortagesFilterForm } from "../../AppState/shortagesFilterFormAction";
import { IMaterUser } from "../../models/user";
import {
  commodityDisplayValueSelector,
  currentTerminalSelector,
  dateMasterSelector,
  exceptionCodesSelector,
  filterFromSelector,
  investigationStatusSelector,
  pageSelector,
  tabPositionSelector,
  terminalSelector,
  terminalValuesSelector,
  valueRangeSelector,
} from "../../AppState/selectors";

type Props = {
  onFilterSubmit?: () => void;
};
const SearchFilterForm: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const tabPosition = useSelector(tabPositionSelector);
  const currentTerminalValue = useSelector(currentTerminalSelector);
  const filterForm: IFilterForm = useSelector(filterFromSelector);

  const pageObject: GridPaginationModel | undefined = useSelector(pageSelector);

  const [date, setDate] = React.useState("");
  const terminalMasterData: string[] = useSelector(terminalValuesSelector);
  const filterValueData: IFilterDropdown[] = useSelector(valueRangeSelector);
  const filterStatusData: IFilterDropdown[] = useSelector(investigationStatusSelector);
  const overagesFilterDateData = useSelector(dateMasterSelector);
  const exceptionCodes = useSelector(exceptionCodesSelector);
  const filterCommodityTypeData = useSelector(commodityDisplayValueSelector);
    const terminalMaster = useSelector(terminalSelector);

  const handleChangeDate = (event: SelectChangeEvent) => {
    setDate(event.target.value);
  };

  const [tid, settid] = React.useState<string[]>([]);
  const handleChangeTID = (value: string[]) => {
    settid(value);
  };

  const [did, setdid] = React.useState<string[]>([]);
  const handleChangeDTID = (value: string[]) => {
    setdid(value);
  };

  const [rTid, setRTid] = React.useState<string[]>([]);
  const handleChangeRTid = (value: string[]) => {
    setRTid(value);
  };


  const [oInputValue, setOInputValue] = React.useState<string>("");
  const [dInputValue, setDInputValue] = React.useState<string>("");
  const [rInputValue, setRInputValue] = React.useState<string>("");
  const [cInputValue, setCInputValue] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(true);

  const onAutoInputChange = (v: string, r: string, type: string = "") => {
    if (r === "input") {
      switch (type) {
        case "origin":
          setOInputValue(v);
          break;
        case "destination":
          setDInputValue(v);
          break;
        case "reporting":
          setRInputValue(v);
          break;
        case "commodity":
          setCInputValue(v);
          break;
      }
    }
  };

  const [exco, setexc] = React.useState<string[]>([]);
  const handleChangeExcep = (event: SelectChangeEvent<typeof exco>) => {
    const {
      target: { value },
    } = event;
    setexc(typeof value === "string" ? value.split(",") : value);
  };

  const [ctype, setctype] = React.useState<string[]>([]);
  const handleChangeCommodityType = (value: string[]) => {
    setctype(value);
  };

  const [value, setValue] = React.useState<string[]>([]);

  const handleChangeValue = (event: SelectChangeEvent<typeof tid>) => {
    const {
      target: { value },
    } = event;
    setValue(typeof value === "string" ? value.split(",") : value);
  };

  const [status, setStatus] = React.useState<string[]>([]);
  const handleStatusChange = (event: SelectChangeEvent<typeof tid>) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === "string" ? value.split(",") : value);
  };

  const [description, setDescription] = React.useState<string | null>("");
  const onDescriptionChange = (event: any) => {
    if (!event) return;
    setDescription(event?.target?.value);
  };
  const [proEntry, setProEntry] = React.useState<string | null>("");
  const [od400ProEntry, setOD400ProEntry] = React.useState<string | null>("");
  const [osdNumber, setOSDNumber] = React.useState<string | null>("");
  const [entryUser, setEntryUser] = React.useState<any>("");
  const [entryUserObject, setEntryUserObject] = React.useState<string | null>("");
  const [entryUserList, setEntryUserList] = React.useState<any>([]);
  const [assignByList, setAssignByList] = React.useState<any>([]);
  const [proMasterData, setProMasterData] = React.useState<string[]>([]);
  const [od400ProMasterData, setOD400ProMasterData] = React.useState<string[]>([]);
  const [osdMasterData, setOsdMasterData] = React.useState<string[]>([]);
  const [assignUser, setAssignUser] = React.useState<any>("");
  const [assignBy, setAssignBy] = React.useState<any>("");
  const [assignUserObject, setAssignUserObject] = React.useState<any>("");
  const [assignByObject, setAssignByObject] = React.useState<any>("");
  const [assignUserList, setAssignUserList] = React.useState<any>([]);

  const onOSDexceptionChange = (value:  string | null, reason?: AutocompleteChangeReason) => {
    if (reason === "clear") setOsdMasterData([]);
    if (!value || value === "undefined") return;
    setOSDNumber(value);
  };

  const onPROentryChange = (value:  string | null, reason?: AutocompleteChangeReason) => {
    if (reason === "clear") setProMasterData([]);
    if (!value || value === "undefined") return;
    setProEntry(value);
  };

  const onOD400PROentryChange = (value: string | null, reason?: AutocompleteChangeReason) => {
    if (reason === "clear") setOD400ProMasterData([]);
    if (!value || value === "undefined") return;
    setOD400ProEntry(value);
  };

  const selectStyle = {
    fontSize: "13px",
  };

  // Get terminal, Commodity exception codes, values, status Filter masters for Dropdown data API call
  const getSearchFiltersAPI = (ParamString: string) => {
    if (ParamString === "commodityType") {
      commodityTypeMasterApi()
        .then((results) => {
          const sortedData: IFilterDropdown[] = results.data.sort(
            (a: any, b: any) => a.code - b.code
          ).map((a :  IFilterDropdown)=> {
            a.code = a?.code?.trim();
            return a
        });
          dispatch(saveFilterCommodityTypeData(sortedData));
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (ParamString === "Exception_Codes") {
      // get Exception code/type for overage list and myAssignment 
      exceptionCodesMasterApi({
        dataType: (() => {
          switch (tabPosition) {
            case ETab.overages:
              return "overage";
            case ETab.myAssignments:
                return "myAssignment";
            default:
              return "shortage";
          }
        })()
      })
        .then((results) => {
          if(tabPosition === ETab.overages){
            dispatch(saveFilterExceptionCodeData(results));
          } else  if(tabPosition === ETab.myAssignments ) {
            dispatch(saveAllExceptionCodeData(results));
          } else {
            dispatch(saveShortageExceptionCodeData(results))
          }
          
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let params: { dataType: string } = {
        dataType: ParamString,
      };
        // store date rage, search value  and status data in redux store.
        getFilterDropdowns(params).then((results) => {
          switch (ParamString) {
            case "overageSearchDateRange":
              const data = results.sort((a: IFilterDropdown, b: IFilterDropdown) => a.serialNumber! - b.serialNumber!)
              dispatch(saveFilterDateData(data));
              break;
            case "overageSearchValues":
              dispatch(saveFilterValueData(results));
              break;
            case "overageStatus":
              dispatch(saveFilterStatusData(results));
              break;
            case "shortageStatus":
              dispatch(saveShortageStatusMaster(results));
              break;
            case "myAssignmentStatus":
                dispatch(saveMyAssignmentStatusMaster(results));
            break;
            default:
              return "";
          }
        }).catch ((error) =>  { 
          console.log(error);
        });
    }
  };

  // Event fire on Entry user changes from Filter form and set entry user

  const onEntryUserChanged = (value: string, reason?: AutocompleteChangeReason) => {
    if (reason === "clear") {
      setEntryUserList([]);
      setEntryUserObject("");
    }
    if (!value || value === "undefined") return;
    setEntryUserObject(value);
  };


  // Call API getMasterProNumberAPI method for get PRO numbers master options to filter Data on grid

  const getProMasterDataAPI = (values: string, proType: string = 'normal') => {  
    if (values && values.length > 1) {
      const params: 
      {
        search: string,
        page: number,
        limit: number,
        osdType: string,
        proType :string
      } = {
        search: values.toString(),
        page: APP.overage.userAPIpageLimit,
        limit: APP.overage.userAPILimit,
        osdType: tabPosition === ETab.shortages ? exceptionCodes.join(","): (proType === DType.od400 ? "NB,OV" : "OV"),
        proType : proType
      };
        getMasterProNumberAPI(params).then((results: any) => {
          setLoading(false);
          if(proType === DType.od400) {
            setOD400ProMasterData(results?.data);
          } else {
            setProMasterData(results?.data);
          }
        }).catch((error) => {
          setLoading(false);
        });
      
    }
  };

  // Helper function to generate terminal list
  const getTerminalList = (
    currentTerminal: TTerminal,
    allTerminals: TTerminal[]
  ) => {
    // If currentTerminal?.region is undefined or null, return all terminals with the same region
    if (!currentTerminal?.region) {
      return allTerminals
        .filter((t) => t.region === currentTerminal.value)
        .map((t) => t.value);
    }

    // Otherwise, return an array containing only the current terminal's value
    return [currentTerminal.value];
  };

  // Memoize columnVisibilityModel, terminal list and exception type
  const terminalList = useMemo(
    () => getTerminalList(currentTerminalValue, terminalMaster),
    [currentTerminalValue, terminalMaster]
  );
  // Memoized destination and reporting based on tabPosition and terminal value
  const destination = useMemo(() => {
    if (tabPosition === ETab.overages || tabPosition === ETab.myAssignments) return [];
    return currentTerminalValue?.value === "All" ? [] : terminalList;
  }, [tabPosition, currentTerminalValue, terminalList]);

  const reporting = useMemo(() => {
    if (tabPosition === ETab.shortages || tabPosition === ETab.myAssignments) return [];
    return currentTerminalValue?.value === "All" ? [] : terminalList;
  }, [tabPosition, currentTerminalValue, terminalList]);

  // Call API getMasterOsdNumberAPI method for get exception numbers master options to filter Data on grid
  const getOsdMasterDataAPI = async (values: any) => {
    console.log("exceptionCodes", exceptionCodes)
    const isCodeEmpty = exceptionCodes === null || exceptionCodes.length === 0;
    let osdTypes: string[] = [""];
    if(isCodeEmpty) {
      if(tabPosition === ETab.shortages) {
        osdTypes = ShortageOsdType;
      } else if(tabPosition === ETab.overages) {
        osdTypes = OverageOsdType;
      } else if(tabPosition === ETab.myAssignments) {
        osdTypes = [...ShortageOsdType, ...OverageOsdType];
      }
    } else {
      osdTypes = exceptionCodes;
    }
    if (values && values.length > 1) {
      let params: any = {
        search: values.toString(),
        page: APP.overage.userAPIpageLimit,
        limit: APP.overage.userAPILimit,
        osdType: osdTypes.join(","),
        destination: destination.join(','),
        reporting: reporting.join(','),
      };
      getMasterOsdNumberAPI(params).then((results: any) => {
        setLoading(false);
        setOsdMasterData(results?.data);
      }).catch ((error) =>  { 
        setLoading(false);
        console.log(error);
      });
    }
  };

  const getFiltersUserDataAPI = async (values: any, type: DType) => {
    if (values && values.length > 1) {
      let params: TMasterSearchParameter = {
        search: values.toString(),
        page: APP.overage.userAPIpageLimit,
        limit: APP.overage.userAPILimit,
      };
        getFilterMasterUser(params).then((users: IMaterUser[]) => {
          setLoading(false);
          if(type === DType.entryBy) {
            setEntryUserList(users);
          } else if(type === DType.assignTo) {
            setAssignUserList(users);
          } else {
            setAssignByList(users);
          }
        }).catch ((error) =>  { 
          setLoading(false);
        })
      } 
  };

  const onAssignUserChanged = (event: any, value: any, reason?: any) => {
    if (reason === "clear") {
      setAssignUserList([]);
      setAssignUserObject("");
    }
    if (!value || value === "undefined") return;
    setAssignUserObject(value);
  };

  const onAssignByChanged = (event: any, value: any, reason?: any) => {
    if (reason === "clear") {
      setAssignByList([]);
      setAssignByObject("");
    }
    if (!value || value === "undefined") return;
    setAssignByObject(value);
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce(
      (value, type) =>
      {
        switch (type) {
          case DType.pro:
            getProMasterDataAPI(value);
          break;
          case DType.od400:
            getProMasterDataAPI(value, DType.od400);
          break;
          case DType.assignTo:
            getFiltersUserDataAPI(value,  DType.assignTo);
          break;
          case DType.assignBy:
            getFiltersUserDataAPI(value,  DType.assignBy);
          break;
          case DType.entryBy:
            getFiltersUserDataAPI(value, DType.entryBy); 
          break;
          case DType.osd:
            getOsdMasterDataAPI(value);
          break;
        }
      },
      1000
    ),[]
  );

  const onInputChange = (value: string, type: string) => {
    if (value === "undefined") return;

    switch (type) {
      case DType.pro:
        setProEntry(value);
        setProMasterData([]);
      break;
      case DType.od400:
        setOD400ProMasterData([]);
        setOD400ProEntry(value);
      break;
      case DType.assignTo:
        setAssignUserList([]);
        setAssignUser(value);
      break;
      case DType.assignBy:
        setAssignByList([]);
        setAssignBy(value);
      break;
      case DType.entryBy:
        setEntryUserList([]);
        setEntryUser(value);
      break;
      case DType.osd:
        setOsdMasterData([]);
        setOSDNumber(value);
      break;
    }
    setLoading(true);
    debounced(value, type);
  };

  // Action perform on "Clear Filter" button click on Filter form reset all filter form value as initials 
  const onFormClear = () => {
    if(tabPosition === ETab.overages){
      dispatch(resetState("OSD_FILTER_RESET"));
    } else if(tabPosition === ETab.myAssignments) {
      dispatch(resetState("MY_ASSIGN_FILTER_RESET"));
    } else if(tabPosition === ETab.shortages) {
      dispatch(resetState("SHORTAGES_FILTER_RESET"));
    }
    if (props.onFilterSubmit) {
      props.onFilterSubmit();
    }
  };

  const onFormSubmit = () => {
    //// on filter form submit button updating redux store for manifest filter form
    //// maintaining same data for filter search, date and terminal picker
    if (props.onFilterSubmit) {
      props.onFilterSubmit();
    }
    const filterFormValues: IFilterForm = {
      search: "",
      date: date,
      origin: tid,
      destination: did,
      reporting: rTid,
      osdType: exco,
      commodityType: ctype,
      description: description?.toString()!,
      proNumber: proEntry?.toString()!,
      od400ProNumber: od400ProEntry?.toString()!,
      osdNumber: osdNumber?.toString()!,
      values: value,
      status: status,
      entryUser: entryUserObject,
      assignedTo: assignUserObject,
      assignedBy: assignByObject,
    };

    const page = { pageSize: _.get(pageObject, "pageSize"), page: 0 };

    if(tabPosition === ETab.overages ) {
      dispatch(saveOverageListPageObject(page));
      dispatch(saveOveragesFilterForm(filterFormValues));
    } else if(tabPosition === ETab.myAssignments ) {
      dispatch(saveMyAssignmentPageObject(page));
      dispatch(saveMyAssignmentsFilterForm(filterFormValues));
    } else if(tabPosition === ETab.shortages ) {
      dispatch(saveShortagesPageObject(page));
      dispatch(saveShortagesFilterForm(filterFormValues));
    }


  };

    // Terminal API call
    const getTerminalsAPI = () => {
          getTerminals().then(async (results: TTerminal[]) => {
          let data: TTerminal[] = SortTerminals(results);
          if(currentTerminalValue?.value === "") { 
            dispatch(TerminalSelection(data.find((terminal) => terminal?.value === UserTerminal() && terminal?.region !== null) ?? data[0]));
          }
          dispatch(saveTerminalMasterState(data));
        }).catch((err) => {
          console.log(err);
      })
    };

  useEffect(() => {

    if (terminalMasterData.length === 0) {
      getTerminalsAPI();
    }
    if (filterForm.entryUser) {
      onInputChange(filterForm.entryUser, DType.entryBy);
    }
    if (filterForm.assignedTo) {
      onInputChange(filterForm.assignedTo, DType.assignTo);
    }
    if (filterForm.assignedBy) {
      onInputChange(filterForm.assignedBy, DType.assignBy);
    }
    if (filterForm.proNumber) {
      onInputChange(filterForm.proNumber, DType.pro);
    }
    if (filterForm.od400ProNumber) {
      onInputChange(filterForm.od400ProNumber, DType.od400);
    }
    if (filterForm.osdNumber) {
      onInputChange(filterForm.osdNumber, DType.osd);
    }

    // Set all filter form values from redux and maintain data as last visit of page filter with same session 
      setDate(filterForm.date!);
      settid(filterForm.origin!);
      setdid(filterForm.destination!);
      setRTid(filterForm.reporting!);
      setexc(filterForm.osdType!);
      setctype(filterForm.commodityType!);
      setValue(filterForm.values!);
      setStatus(filterForm.status!);
      setDescription(filterForm.description);
      setProEntry(filterForm.proNumber);
      setOD400ProEntry(filterForm.od400ProNumber);
      setOSDNumber(filterForm.osdNumber);
      setEntryUserObject(filterForm.entryUser);
      setAssignUserObject(filterForm.assignedTo);
      setAssignByObject(filterForm.assignedBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabPosition, filterForm]);

  useEffect(() => {
    if (
      filterCommodityTypeData === null ||
      filterCommodityTypeData.length === 0
    ) {
      getSearchFiltersAPI("commodityType");
    }
    if (
      exceptionCodes === null ||
      exceptionCodes.length === 0
    ) {
      getSearchFiltersAPI("Exception_Codes");
    }
    if (
      overagesFilterDateData === null ||
      overagesFilterDateData.length === 0
    ) {
      getSearchFiltersAPI("overageSearchDateRange");
    }
    if (filterValueData === null || filterValueData.length === 0) {
      getSearchFiltersAPI("overageSearchValues");
    }
    if (filterStatusData === null || filterStatusData.length === 0) {
      if(tabPosition === ETab.shortages) {
        getSearchFiltersAPI("shortageStatus");
      } else if(tabPosition === ETab.overages) {
        getSearchFiltersAPI("overageStatus");
      } else{
        getSearchFiltersAPI("overageStatus");
        getSearchFiltersAPI("shortageStatus");
        // getSearchFiltersAPI("myAssignmentStatus");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-filter">
      <div className="filter">
        <Grid container spacing={2} className="filter-margin">
          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="date">
                Date
              </InputLabel>
              <Select
                variant="outlined"
                id="date"
                placeholder="Select a Date"
                className="select"
                value={date}
                fullWidth
                onChange={handleChangeDate}
                style={selectStyle}
                IconComponent={() => (
                  <div className="dropdown-icon-group">
                    <ArrowDropUpIcon className="icon-style" />{" "}
                    <ArrowDropDownIcon className="icon-style" />{" "}
                  </div>
                )}
              >
                {overagesFilterDateData.map((obj: IFilterDropdown) => (
                  <MenuItem
                    style={selectStyle}
                    key={obj?.serialNumber}
                    value={obj?.serialNumber}
                  >
                    {obj?.displayValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12} className="hide-no-option">
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="origin">
                Origin TID
              </InputLabel>
              <Autocomplete
                key="search-tid"
                disablePortal
                className="custom-autocomplete"
                multiple
                forcePopupIcon={false}
                getOptionLabel={(option) => option || ""}
                disableCloseOnSelect
                value={tid}
                style={selectStyle}
                options={terminalMasterData}
                onChange={(e, v) => handleChangeTID(v)}
                inputValue={oInputValue}
                onClose={(e) => {
                  setOInputValue("");
                }}
                onInputChange={(e, v, r) => onAutoInputChange(v, r, "origin")}
                renderOption={(props, option) => (
                  <li {...props} title={option}>
                    <Checkbox checked={tid.indexOf(option) > -1} />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" className="test" />
                )}
                // renderTags={renderTags}
                renderTags={(value) => (
                  <div
                    className="auto-complete-selected-container"
                    title={value.join(", ")}
                  >
                    {value.join(", ")}
                  </div>
                )} // Add renderTags property to customize rendering of selected values
              />
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12} className="hide-no-option">
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="destinationTID">
               {tabPosition !== 3 ? "Destination TID": "Reporting TID" } 
              </InputLabel>
              {tabPosition !== 3 ? (<Autocomplete
              key="search-did"
                className="custom-autocomplete"
                disablePortal
                multiple
                forcePopupIcon={false}
                value={did}
                style={selectStyle}
                options={terminalMasterData}
                disableCloseOnSelect
                onChange={(e, v) => handleChangeDTID(v)}
                getOptionLabel={(option) => option || ""}
                inputValue={dInputValue}
                onClose={(e) => {
                  setDInputValue("");
                }}
                onInputChange={(e, v, r) =>
                  onAutoInputChange(v, r, "destination")
                }
                renderOption={(props, option) => (
                  <li {...props} title={option}>
                    <Checkbox checked={did.indexOf(option) > -1} />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                renderTags={(value) => (
                  <div
                    className="auto-complete-selected-container"
                    title={value.join(", ")}
                  >
                    {value.join(", ")}
                  </div>
                )} // Add renderTags property to customize rendering of selected values
              />) : (

              <Autocomplete
                key="search-rTid"
                className="custom-autocomplete"
                disablePortal
                multiple
                forcePopupIcon={false}
                value={rTid}
                style={selectStyle}
                options={terminalMasterData}
                disableCloseOnSelect
                onChange={(e, v) => handleChangeRTid(v)}
                getOptionLabel={(option) => option || ""}
                inputValue={rInputValue}
                onClose={(e) => {
                  setRInputValue("");
                }}
                onInputChange={(e, v, r) =>
                  onAutoInputChange(v, r, "reporting")
                }
                renderOption={(props, option) => (
                  <li {...props} title={option}>
                    <Checkbox checked={rTid.indexOf(option) > -1} />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                renderTags={(value) => (
                  <div
                    className="auto-complete-selected-container"
                    title={value.join(", ")}
                  >
                    {value.join(", ")}
                  </div>
                )} // Add renderTags property to customize rendering of selected values
              />)}
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="exceptionCode">
                Exception Code
              </InputLabel>
              <Select
                labelId="exceptionCode"
                id="exceptionCode"
                multiple
                className="select"
                value={exco}
                onChange={handleChangeExcep}
                style={selectStyle}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                IconComponent={() => (
                  <div className="dropdown-icon-group">
                    <ArrowDropUpIcon className="icon-style" />{" "}
                    <ArrowDropDownIcon className="icon-style" />{" "}
                  </div>
                )}
              >
                {exceptionCodes.map((obj) => (
                  <MenuItem className="font-override" key={obj} value={obj}>
                    <Checkbox checked={exco.indexOf(obj) > -1} />
                    <ListItemText className="font-override" primary={obj} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="filter-margin">
          <Grid item md={3} xs={12} className="hide-no-option">
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="commodityType">
                Commodity
              </InputLabel>
              <Autocomplete
              key="search-ctype"
                className="custom-autocomplete"
                disablePortal
                multiple
                getOptionLabel={(option) => option || ""}
                value={ctype}
                inputValue={cInputValue}
                onClose={(e) => {
                  setCInputValue("");
                }}
                onInputChange={(e, v, r) =>
                  onAutoInputChange(v, r, "commodity")
                }
                forcePopupIcon={false}
                options={filterCommodityTypeData.sort()}
                disableCloseOnSelect
                onChange={(e, v) => handleChangeCommodityType(v)}
                renderOption={(props, option) => (
                  <li {...props} title={option}>
                    <Checkbox checked={ctype.indexOf(option) > -1} />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                renderTags={(value) => (
                  <div
                    className="auto-complete-selected-container"
                    title={value.join(", ")}
                  >
                    {value.join(", ")}
                  </div>
                )} // Add renderTags property to customize rendering of selected values
              />
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="description">
                Description
              </InputLabel>
              <TextField
                id="outlined-basic"
                onChange={onDescriptionChange}
                variant="outlined"
                value={description}
              />
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="pro">
                PRO #
              </InputLabel>
              <Autocomplete
               key="search-proEntry"
                disablePortal
                noOptionsText={
                  NoOptionsText(proEntry!, loading, EAutoSearchFieldName.pro)
                }
                size="small"
                value={proEntry}
                onInputChange={(e, v) => onInputChange(v, DType.pro)}
                onChange={(e, v, r) => onPROentryChange(v, r)}
                forcePopupIcon={false}
                className="autocomplete"
                getOptionLabel={(option) => option}
                inputValue={proEntry!}
                options={proMasterData}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>

         { [ETab.myAssignments, ETab.overages].indexOf(tabPosition) !== -1 && 
         <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="pro">
               OD400 PRO #
              </InputLabel>
              <Autocomplete
                key="search-od400ProEntry"
                disablePortal
                noOptionsText={
                  NoOptionsText(od400ProEntry!, loading, EAutoSearchFieldName.od400)
                }
                size="small"
                value={od400ProEntry}
                onInputChange={(e, v) => onInputChange(v, DType.od400)}
                onChange={(e, v, r) => onOD400PROentryChange(v, r)}
                forcePopupIcon={false}
                className="autocomplete"
                getOptionLabel={(option) => option || ""}
                inputValue={od400ProEntry!}
                options={od400ProMasterData}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
        } { [ETab.shortages].indexOf(tabPosition) !== -1 && 
          <Grid item md={3} xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel style={selectStyle} shrink={true} id="osdNumber">
              Exception #
            </InputLabel>
            <Autocomplete
            
              disablePortal
              size="small"
              noOptionsText={
                NoOptionsText(osdNumber!, loading, EAutoSearchFieldName.osdNumber)
              }
              key="search-osdNumber"
              value={osdNumber}
              onInputChange={(e, v) => onInputChange(v, DType.osd)}
              onChange={(e, v, r) => onOSDexceptionChange(v, r)}
              forcePopupIcon={false}
              className="autocomplete"
              inputValue={osdNumber!}
              getOptionLabel={(option) => option}
              options={osdMasterData}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </Grid>
        }
        </Grid>

        <Grid container spacing={2} className="filter-margin">
        { [ETab.myAssignments, ETab.overages].indexOf(tabPosition) !== -1 && 
          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="osdNumber">
                Exception #
              </InputLabel>
              <Autocomplete
                disablePortal
                size="small"
                noOptionsText={
                  NoOptionsText(osdNumber!, loading, EAutoSearchFieldName.osdNumber)
                }
                key="search-osdNumber2"
                value={osdNumber}
                onInputChange={(e, v) => onInputChange(v, DType.osd)}
                onChange={(e, v, r) => onOSDexceptionChange(v, r)}
                forcePopupIcon={false}
                className="autocomplete"
                inputValue={osdNumber!}
                getOptionLabel={(option) => option}
                options={osdMasterData}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          }
          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="value">
                Value Range
              </InputLabel>
              <Select
                labelId="value"
                id="value"
                multiple
                className="select"
                value={value}
                onChange={handleChangeValue}
                style={selectStyle}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                IconComponent={() => (
                  <div className="dropdown-icon-group">
                    <ArrowDropUpIcon className="icon-style" />{" "}
                    <ArrowDropDownIcon className="icon-style" />{" "}
                  </div>
                )}
              >
                {filterValueData.map((obj: IFilterDropdown) => (
                  <MenuItem
                    className="font-override"
                    key={obj.displayValue}
                    value={obj.actualValue}
                  >
                    <Checkbox checked={value.indexOf(obj.actualValue) > -1} />
                    <ListItemText
                      className="font-override"
                      primary={obj.displayValue}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="status">
                Status
              </InputLabel>
              <Select
                labelId="status"
                id="status"
                multiple
                className="select"
                value={status}
                onChange={handleStatusChange}
                style={selectStyle}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                IconComponent={() => (
                  <div className="dropdown-icon-group">
                    <ArrowDropUpIcon className="icon-style" />{" "}
                    <ArrowDropDownIcon className="icon-style" />{" "}
                  </div>
                )}
              >
                {filterStatusData.map((obj: IFilterDropdown) => (
                  <MenuItem
                    className="font-override"
                    key={obj.displayValue}
                    value={obj.displayValue}
                  >
                    <Checkbox checked={status.indexOf(obj.displayValue) > -1} />
                    <ListItemText
                      className="font-override"
                      primary={obj.displayValue}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="entryUser">
                Entry User
              </InputLabel>
              <Autocomplete
                disablePortal
                noOptionsText={
                  NoOptionsText(entryUser, loading, EAutoSearchFieldName.username)
                }
                size="small"
                key="search-entryUser"
                value={entryUser}
                onInputChange={(e, v) => onInputChange(v, DType.entryBy)}
                onChange={(e, v, r) => onEntryUserChanged(v, r)}
                forcePopupIcon={false}
                className="autocomplete"
                inputValue={entryUser}
                getOptionLabel={(option) =>  option !== undefined && option.as400Id}
                filterOptions={(options, { inputValue }) =>
                options.filter(option =>
                  option.nameFullLc.concat(" ", option.as400Id).toLowerCase().includes(inputValue.toLowerCase())
                )
              }
                options={entryUserList}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          {[ETab.shortages].indexOf(tabPosition) !== -1  && 
            <Grid item md={3} xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel style={selectStyle} shrink={true} id="assignedTo">
                Assigned To
              </InputLabel>
              <Autocomplete
                disablePortal
                noOptionsText={
                  NoOptionsText(assignUser, loading, EAutoSearchFieldName.name)
                }
                size="small"
                key="search-assignedTo"
                value={assignUser}
                onInputChange={(e, v) => onInputChange(v, DType.assignTo)}
                onChange={onAssignUserChanged}
                inputValue={assignUser}
                forcePopupIcon={false}
                className="autocomplete"
                options={assignUserList}
                getOptionLabel={(option) => option !== undefined && option.nameFullLc }
                filterOptions={(options, { inputValue }) =>
                options.filter(option =>
                  option.nameFullLc.concat(" ", option.as400Id).toLowerCase().includes(inputValue.toLowerCase())
                )
              }
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          }
        </Grid>

        <Grid container spacing={2} className="filter-margin align-items-end">
          
          {tabPosition === ETab.overages && 
            <Grid item md={3} xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel style={selectStyle} shrink={true} id="assignedTo">
                  Assigned To
                </InputLabel>
                <Autocomplete
                  disablePortal
                  noOptionsText={
                    NoOptionsText(assignUser, loading, EAutoSearchFieldName.name)
                  }
                  size="small"
                  key="search-assignedTo"
                  value={assignUser}
                  onInputChange={(e, v) => onInputChange(v, DType.assignTo)}
                  onChange={onAssignUserChanged}
                  inputValue={assignUser}
                  forcePopupIcon={false}
                  className="autocomplete"
                  options={assignUserList}
                  getOptionLabel={(option) => option !== undefined && option.nameFullLc }
                  filterOptions={(options, { inputValue }) =>
                  options.filter(option =>
                    option.nameFullLc.concat(" ", option.as400Id).toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </Grid>
          }
          {[ETab.myAssignments].indexOf(tabPosition) !== -1  && 
          <>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel style={selectStyle} shrink={true} id="assignedTo">
                  Assigned By
                </InputLabel>
                <Autocomplete
                  disablePortal
                  noOptionsText={
                    NoOptionsText(assignBy, loading, EAutoSearchFieldName.name)
                  }
                  size="small"
                  key="search-assignedBy"
                  value={assignBy}
                  onInputChange={(e, v) => onInputChange(v, DType.assignBy)}
                  onChange={onAssignByChanged}
                  inputValue={assignBy}
                  forcePopupIcon={false}
                  className="autocomplete"
                  options={assignByList}
                  getOptionLabel={(option) => option !== undefined && option.nameFullLc }
                  filterOptions={(options, { inputValue }) =>
                  options.filter(option =>
                    option.nameFullLc.concat(" ", option.as400Id).toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel style={selectStyle} shrink={true} id="destinationTID">
                  Reporting TID
                </InputLabel>
                  <Autocomplete
                  key="search-rTid"
                  className="custom-autocomplete"
                  disablePortal
                  multiple
                  forcePopupIcon={false}
                  value={rTid}
                  style={selectStyle}
                  options={terminalMasterData}
                  disableCloseOnSelect
                  onChange={(e, v) => handleChangeRTid(v)}
                  getOptionLabel={(option) => option || ""}
                  inputValue={rInputValue}
                  onClose={(e) => {
                    setRInputValue("");
                  }}
                  onInputChange={(e, v, r) =>
                    onAutoInputChange(v, r, "reporting")
                  }
                  renderOption={(props, option) => (
                    <li {...props} title={option}>
                      <Checkbox checked={rTid.indexOf(option) > -1} />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  renderTags={(value) => (
                    <div
                      className="auto-complete-selected-container"
                      title={value.join(", ")}
                    >
                      {value.join(", ")}
                    </div>
                  )}
                />
              </FormControl>
            </Grid> 
          </>
          }

          <Grid
          item
          md={tabPosition === ETab.overages ? 9 : 12}
          xs={12}
          display="flex"
          justifyContent="flex-end"
          className="filter-margin"
        >
          <Button onClick={onFormClear} variant="text" className="clear-link">
            Clear Filter
          </Button>
          <Button onClick={onFormSubmit} variant="contained" className="submit">
            Search
          </Button>
        </Grid>
        </Grid>

        
      </div>
    </div>
  );
};

export default SearchFilterForm;
