import { IFilterForm, IFilterDropdown, TTerminal } from "./overagesFilterFormActions";
import NOTIFICATION_TYPE from "./NotificationType";
import { APP } from "../constants/app";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IUserPermissions } from "../models/user";

/**
 * The type definition of our app's central state (Redux State)
 */

//// define property
export interface AppState {
  readonly isLoading: boolean;
  readonly isLoggedIn: boolean;
  readonly isFormValueChanged: boolean;
  readonly _persist: any;
  readonly tabPosition: number;
  readonly isPrivilegedUser: boolean;
  readonly userPermissions: IUserPermissions;
  readonly activeTerminal: TTerminal;
  readonly terminalMaster: TTerminal[];
  readonly overagesFilterDateData : IFilterDropdown[];
  readonly filterCommodityTypeData : IFilterDropdown[];
  readonly packageOptionMasterData:  IFilterDropdown[];
  readonly filterExceptionCodeData : string[];
  readonly shortageExceptionCodeData : string[];
  readonly docTypeMaster : string[];
  readonly allExceptionCodeData : string[];
  readonly filterValueData : IFilterDropdown[];
  readonly filterStatusData : IFilterDropdown[];
  readonly shortageStatusMasterData : IFilterDropdown[];
  readonly myAssignmentStatusMaster: IFilterDropdown[];
  readonly notificationType: NOTIFICATION_TYPE;
  readonly message: string;
  readonly notificationTime: string;
  readonly notificationChange: string;
  readonly overageSortObjectData?: any;
  readonly overageListPageObject: GridPaginationModel;
  readonly myAssignmentSortObjectData?: any;
  readonly myAssignmentPageObject: GridPaginationModel;
  readonly shortagesSortObject?: any;
  readonly shortagesPageObject: GridPaginationModel;
  readonly overagesFilterForm: IFilterForm;
  readonly myAssignmentsFilterForm: IFilterForm;
  readonly shortagesFilterForm: IFilterForm;
}

//// set Initial state of the property
export const DEFAULT_APP_STATE: AppState = {
  isLoading: false,
  isFormValueChanged: false,
  tabPosition: 0,
  isPrivilegedUser: false,
  userPermissions: {
    "shortages": true,
    "overages": true
  },
  isLoggedIn: false,
  activeTerminal: { value: "", region: null },
  terminalMaster: [],
  docTypeMaster: [],
  overagesFilterDateData: [],
  filterCommodityTypeData: [],
  packageOptionMasterData: [],
  filterExceptionCodeData: [],
  shortageExceptionCodeData: [],
  allExceptionCodeData: [],
  overageSortObjectData:  APP.overage.sortModel,
  overageListPageObject: APP.overage.pageObject,
  myAssignmentSortObjectData:  APP.overage.sortModel,
  myAssignmentPageObject: APP.overage.pageObject,
  shortagesSortObject: APP.overage.sortModel,
  shortagesPageObject: APP.overage.pageObject,
  filterValueData: [],
  filterStatusData: [],
  shortageStatusMasterData: [],
  myAssignmentStatusMaster: [],
  overagesFilterForm: {
    search: "",
    date: "",
    origin: [],
    destination: [],
    reporting: [],
    osdType: [],
    commodityType: [],
    description: "",
    proNumber: "",
    od400ProNumber: "",
    osdNumber: "",
    values: [],
    status: [],
    entryUser: "",
    assignedTo: "",
  },
  myAssignmentsFilterForm:  {
    search: "",
    date: "",
    origin: [],
    destination: [],
    reporting: [],
    osdType: [],
    commodityType: [],
    description: "",
    proNumber: "",
    od400ProNumber: "",
    osdNumber: "",
    values: [],
    status: [],
    entryUser: "",
    assignedBy: "",
  },
  shortagesFilterForm : {
    search: "",
    date: "",
    origin: [],
    destination: [],
    osdType: [],
    reporting: [],
    commodityType: [],
    description: "",
    proNumber: "",
    od400ProNumber: "",
    osdNumber: "",
    values: [],
    status: [],
    entryUser: "",
    assignedBy: "",
  },
  message: "",
  notificationTime: "",
  notificationChange: "",
  // defauld field //
  _persist: undefined,
  notificationType: NOTIFICATION_TYPE.SUCCESS,
};
