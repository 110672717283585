import React, { useEffect, useRef } from "react";
import { AppState } from "../../AppState/appState";
import "./Dashboard.css";
import Grid from "@mui/material/Grid";
import SearchWithBarcode from "../../components/search/searchDashboard";
import KRACard from "../../components/kraCard/KRACard";
import { getDashboardKRA } from "../../API/getDashboardKRA";
import { useDispatch, useSelector } from "react-redux";
import { AppURLs, ETab } from "../../constants/app";
import Loader from "../../components/loader/loader";
import { TDashboardKRA } from "../../models/dashboard";
import { FooterTabPosition } from "../../AppState/footerTabPosition";
import { TTerminal } from "../../AppState/overagesFilterFormActions";
import {
  hasOveragesPermissionSelector,
  hasShortagesPermissionSelector,
} from "../../AppState/selectors/userPermissionsSelectors";

function Dashboard() {
  const dispatch = useDispatch();
  const [countKRA, setCountKRA] = React.useState<TDashboardKRA>();
  const currentTerminalValue = useSelector(
    (state: AppState) => state.activeTerminal
  );
  const previousTerminalValue = useRef<TTerminal | null>(null);
  const terminals = useSelector((state: AppState) => state.terminalMaster);

  const hasOveragesPermission = useSelector(hasOveragesPermissionSelector);
  const hasShortagesPermission = useSelector(hasShortagesPermissionSelector);

  const [pageLoading, setPageLoading] = React.useState<boolean>(false);

  //Dashboard KRA API call
  const getDashboardKRAdata = async () => {
    setPageLoading(true);
    const terminalList: string[] =
      currentTerminalValue?.region === null
        ? terminals
            .filter(
              (terminal) => terminal?.region === currentTerminalValue?.value
            )
            .map((t) => t.value)
        : [currentTerminalValue?.value];
    let params: any = {
      terminal: terminalList?.[0] === "All" ? [] : terminalList,
    };
    getDashboardKRA(params)
      .then((results: any) => {
        setPageLoading(false);
        setCountKRA(results);
      })
      .catch((err) => {
        setPageLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    // Function to handle the data fetch
    const fetchDashboardData = () => {
      if (
        currentTerminalValue?.value !== "" &&
        currentTerminalValue !== previousTerminalValue.current
      ) {
        getDashboardKRAdata();
      }
    };

    // Call fetchDashboardData on component mount or when navigating back to this page
    fetchDashboardData();

    // Update previous terminal value after fetching data
    previousTerminalValue.current = currentTerminalValue;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTerminalValue]);

  useEffect(() => {
    dispatch(FooterTabPosition(ETab.dashboard));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="layout" data-test="dashboard">
      <Grid container className="center-container dashboard">
        <Grid item xl={6} md={6} sm={8}>
          <h2>What would you like to find today?</h2>

          {/* Search Filter  */}
          <SearchWithBarcode />
        </Grid>
      </Grid>

      <Grid
        container
        className="center-container card-container"
        style={{ gap: 15 }}
      >
        {hasShortagesPermission && (
          <Grid item xl={2} md={2} sm={3} xs={4} className="card-bg">
            <KRACard
              data-test="KRAcard"
              navigateTo={AppURLs.shortages}
              heading={"Shortages"}
              data={countKRA?.shortages}
            />
          </Grid>
        )}

        {hasOveragesPermission && (
          <Grid item xl={2} md={2} sm={3} xs={4} className="card-bg">
            <KRACard
              data-test="KRAcard"
              navigateTo={AppURLs.overages}
              heading={"Overages"}
              data={countKRA?.overages}
            />
          </Grid>
        )}

        <Grid item xl={2} md={2} sm={3} xs={4} className="card-bg">
          <KRACard
            data-test="KRAcard"
            navigateTo={AppURLs.myAssignments}
            heading={"My Assignments"}
            data={countKRA?.myAssignments}
          />
        </Grid>
      </Grid>
      <Loader pageLoader={pageLoading}></Loader>
    </div>
  );
}

export default Dashboard;
