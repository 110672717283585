import { createSelector } from "reselect";
import { AppState } from "../appState";
import { ETab } from "../../constants/app";
import { tabPositionSelector } from "./tabSelectors";

// Base selectors
const overagesPageSelector = (state: AppState) => state.overageListPageObject;
const myAssignmentsPageSelector = (state: AppState) =>
  state.myAssignmentPageObject;
const shortagesPageSelector = (state: AppState) => state.shortagesPageObject;

// Memoized selector to get Pagination page object based on tab position
export const pageSelector = createSelector(
  [
    tabPositionSelector,
    overagesPageSelector,
    myAssignmentsPageSelector,
    shortagesPageSelector,
  ],
  (
    tabPosition,
    overagesPageObject,
    myAssignmentsPageObject,
    shortagesPageObject
  ) => {
    if (tabPosition === ETab.overages) {
      return overagesPageObject;
    } else if (tabPosition === ETab.myAssignments) {
      return myAssignmentsPageObject;
    } else {
      return shortagesPageObject;
    }
  }
);

const overagesSortSelector = (state: AppState) => state.overageSortObjectData;
const myAssignmentsSortSelector = (state: AppState) =>
  state.myAssignmentSortObjectData;
const shortagesSortSelector = (state: AppState) => state.shortagesSortObject;

// Memoized selector to get data table sorting based on tab position
export const sortSelector = createSelector(
  [
    tabPositionSelector,
    overagesSortSelector,
    myAssignmentsSortSelector,
    shortagesSortSelector,
  ],
  (
    tabPosition,
    overagesSortSelector,
    myAssignmentsSortSelector,
    shortagesSortSelector
  ) => {
    if (tabPosition === ETab.overages) {
      return overagesSortSelector;
    } else if (tabPosition === ETab.myAssignments) {
      return myAssignmentsSortSelector;
    } else {
      return shortagesSortSelector;
    }
  }
);
