import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { BNFFollowupAPI } from "../../../API/bnfFollowUp";
import Loader from "../../../components/loader/loader";
import { PopoutNotification } from "../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../../AppState/NotificationType";
import { useDispatch } from "react-redux";
import { EBNFStatus } from "../../../constants/shortage";
import { useNavigate } from "react-router-dom";
import { AppURLs } from "../../../constants/app";

type Props = {
  osdNumber?: number;
  hasMovementSinceBNFValue?: string;
  investigationStatusNumber?: number;
  setBNFStatus: (status: number) => void;
};

const BNFInvestigation: React.FC<Props> = ({
  osdNumber = 0,
  hasMovementSinceBNFValue,
  investigationStatusNumber,
  setBNFStatus,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasMovementSinceBNF, setHasMovementSinceBNF] = useState("");
  const [formFieldsDisabled, setFormFieldsDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(0);

  const enableEditing = useCallback(() => {
    setFormFieldsDisabled(false);
  }, []);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasMovementSinceBNF(event.target.value);
  };

  const handleCancelClick = useCallback(() => {
    setHasMovementSinceBNF(""); // Reset value if needed
  }, []);

  const movementMessage =
    hasMovementSinceBNF === "Y"
      ? "Clear the BNF exception in the 78-6 and ensure freight goes out to delivery ASAP"
      : "Clear the BNF exception in the 78-6 and enter an AS exception";

  const followup = useCallback(async () => {
    setLoading(true);
    try {
      const res = await BNFFollowupAPI({ hasMovementSinceBNF }, osdNumber);
      const { statusNumber } = res;
      setStatus(statusNumber);
      setBNFStatus(statusNumber);
      setFormFieldsDisabled(true);
      let message = "Clear the BNF exception in the 78-6 and ensure freight go out to delivery ASAP";
      if(hasMovementSinceBNF === "N" ) {
        message = "Clear the BNF exception in the 78-6 and Enter an AS exception";
      }
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.SUCCESS,
          message,
          Date.now().toString()
        )
      );
      if(hasMovementSinceBNF === "N" ) {
        navigate(AppURLs.shortageEntry, { state: { osdNumber } });
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : "BNF follow-up failed. Please try again";
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          errorMessage,
          Date.now().toString()
        )
      );
      console.error("BNF follow-up error:", err); // More detailed error log
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasMovementSinceBNF, osdNumber, setBNFStatus]);

  useEffect(() => {
    if (
      hasMovementSinceBNFValue &&
      hasMovementSinceBNFValue !== hasMovementSinceBNF
    )
      setHasMovementSinceBNF(hasMovementSinceBNFValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMovementSinceBNFValue]);

  useEffect(() => {
    if (investigationStatusNumber && investigationStatusNumber !== status)
      setStatus(investigationStatusNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigationStatusNumber]);

  return (
    <>
      <Grid container spacing={2} className="filter-margin">
        <Grid item md={6} xs={12}>
          <FormControl className="radio-group question-group p-5">
            <FormLabel>Has this shown any movement since BNF</FormLabel>
            <RadioGroup
              row
              name="movement"
              value={hasMovementSinceBNF}
              onChange={handleChangeRadio}
            >
              <FormControlLabel
                value="Y"
                control={<Radio disabled={formFieldsDisabled} />}
                label="Yes"
              />
              <FormControlLabel
                value="N"
                control={<Radio disabled={formFieldsDisabled} />}
                label="No"
              />
            </RadioGroup>
            {hasMovementSinceBNF && (
              <p className="mb-0 fs">{movementMessage}</p>
            )}
          </FormControl>
        </Grid>

        {status !== EBNFStatus.needsCleared && (
          <Grid item md={12} xs={12} className="btn-group-submit">
            {formFieldsDisabled ? (
              <Button
                variant="contained"
                onClick={enableEditing}
                className="assign ml mr-0"
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="clear-btn mr-0 ml"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={followup}
                  className={
                    hasMovementSinceBNF !== "Y" && hasMovementSinceBNF !== "N"
                      ? "disable-btn disable entry ml"
                      : "entry ml"
                  }
                >
                  {hasMovementSinceBNF === "N" ? "Save & Enter AS" : "Save"}
                </Button>
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Loader pageLoader={loading}></Loader>
    </>
  );
};

export default React.memo(BNFInvestigation);
